import './CarpoolViewsNavbar.css';

function CarpoolViewsNavbar(props: {view: 'calendar' | 'info', setView: (view: 'calendar' | 'info') => void, showNotify: boolean}) {

  return (
    <nav id="carpool-navbar">
      <ul>
        <li onClick={() => props.setView('calendar')} className={props.view === 'calendar' ? 'active-tab' : undefined}>
          Calendar
        </li>
        <li onClick={() => props.setView('info')} className={props.view === 'info' ? 'active-tab' : undefined} style={{display: 'flex'}}>
          Info
          <p>{props.showNotify && '🔴'}</p>
        </li>
      </ul>
    </nav>
  );
}

export default CarpoolViewsNavbar;