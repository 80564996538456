import { createSlice } from "@reduxjs/toolkit";
import { CarpoolPartOfState, allSlicesState, userSliceState } from "../storeStates";

const initialState: userSliceState = {
  userId: null,
  displayName: null,
  carpoolsPartOf: [],
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
    addCarpoolsPartOf(state, action: {type: string; payload: CarpoolPartOfState}) {
      state.carpoolsPartOf = [...state.carpoolsPartOf, action.payload];
    },
    setCarpoolsPartOf(state, action: {type: string; payload: CarpoolPartOfState[]}) {
      state.carpoolsPartOf = [...action.payload];
    },
    addFamilyToCarpool(state, action: {type: string; payload: {carpoolId: string, familyId: string, familyName: string}}) {
      state.carpoolsPartOf = state.carpoolsPartOf.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            familyPartOf: {familyId: action.payload.familyId, familyName: action.payload.familyName},
          };
        }
        return carpool;
      });
    },
    updateFamilyPartOf(state, action: {type: string; payload: {carpoolId: string, familyId: string, familyName: string}}) {
      state.carpoolsPartOf = state.carpoolsPartOf.map((carpoolPartOf) => {
        if (carpoolPartOf.carpoolId === action.payload.carpoolId) {
          return {
            ...carpoolPartOf,
            familyPartOf: {familyId: action.payload.familyId, familyName: action.payload.familyName}
          };
        }
        return carpoolPartOf;
      });
    },
    removeFamilyPartOf(state, action: {type: string; payload: {carpoolId: string}}) {
      state.carpoolsPartOf = state.carpoolsPartOf.map((carpoolPartOf) => {
        if (carpoolPartOf.carpoolId === action.payload.carpoolId) {
          return {
            ...carpoolPartOf,
            familyPartOf: null,
          };
        }
        return carpoolPartOf;
      });
    },
    removeCarpoolPartOf(state, action: {type: string; payload: {carpoolId: string}}) {
      state.carpoolsPartOf = state.carpoolsPartOf.filter((carpoolPartOf) => carpoolPartOf.carpoolId !== action.payload.carpoolId);
    },
  },
});

export const { removeCarpoolPartOf, removeFamilyPartOf, setUserId, updateFamilyPartOf, addCarpoolsPartOf, setCarpoolsPartOf, setUserDisplayName, addFamilyToCarpool } = userSlice.actions;

export const selectUserId = (state: allSlicesState) => state.user.userId;
export const selectUserDisplayName = (state: allSlicesState) => state.user.displayName;
export const selectCarpoolsPartOf = (state: allSlicesState) => state.user.carpoolsPartOf;
export const selectFamilyPartOfInCarpool = (carpoolId: string) => (state: allSlicesState) => {
  const carpoolPartOf = state.user.carpoolsPartOf.find((carpool) => carpool.carpoolId === carpoolId);
  if (!carpoolPartOf) return undefined;
  return carpoolPartOf.familyPartOf;
}

export default userSlice.reducer;