import { useDispatch, useSelector } from 'react-redux';
import { RequestingAccessPerson } from '../store/storeStates';
import { selectCarpoolNameById } from '../store/carpools/carpoolsSlice';
import { selectUserDisplayName, selectUserId } from '../store/user/userSlice';
import { grantAccessAction, removeRequestAction } from '../store/carpools/carpoolsActions';

function GrantAccessDecision(props: {carpoolId: string, person: RequestingAccessPerson}) {
  const { carpoolId, person } = props;
  const dispatch = useDispatch();
  const userId: string = useSelector(selectUserId) || "";
  const userDisplayName: string = useSelector(selectUserDisplayName) || "";
  const carpoolName: string = useSelector(selectCarpoolNameById(carpoolId)) || "";

  return (
    <div className="grant-access-row">
      <p>{person.userDisplayName}</p>
      <button className='secondary-btn' onClick={() => dispatch(grantAccessAction({carpoolId, person, userId, userDisplayName, carpoolName}))}>Grant</button>
      <button className='secondary-btn' onClick={() => dispatch(removeRequestAction({carpoolId, person}))}>Deny</button>
    </div>
  );
}

export default GrantAccessDecision;