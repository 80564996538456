import React from 'react';
import MultiDatePicker, { DateObject } from 'react-multi-date-picker';

function AddFamilyFormItems(props: {familyName: string, vacationDays: DateObject[], daysAvailable: string[], setFamilyName: (name: string) => void, handleVacationDaysChange: (dates: DateObject[]) => void, handleDaysAvailableChange: (event: React.ChangeEvent<HTMLSelectElement>) => void}) {
  const { familyName, vacationDays, daysAvailable, setFamilyName, handleVacationDaysChange, handleDaysAvailableChange } = props;

  return (
    <>
      <p>Family Name:</p>
      <input type="text" required value={familyName} onChange={(e) => setFamilyName(e.target.value)} />

      <p>Vacation days:</p>
      <MultiDatePicker
        multiple
        value={vacationDays}
        onChange={handleVacationDaysChange}
        format="YYYY-MM-DD"
      />

      <p>Days you are available to both drop/pick during the week:</p>
      <select multiple required value={daysAvailable} onChange={handleDaysAvailableChange}>
        <option value="Monday">Monday</option>
        <option value="Tuesday">Tuesday</option>
        <option value="Wednesday">Wednesday</option>
        <option value="Thursday">Thursday</option>
        <option value="Friday">Friday</option>
      </select>
    </>
  );
}
export default AddFamilyFormItems;