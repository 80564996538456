import { NavLink } from 'react-router-dom';

import './navbar.css';

function Navbar(props: {userId: string | null}) {
  const { userId } = props;

  return (
    <nav>
      <p>Carpool Schedule</p>
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            Home
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
            to="/create-carpool"
          >
            Create
          </NavLink>
        </li> */}
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
            to="/auth"
          >
            {userId ? "Profile" : "Log In"}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;