import { useState, useRef } from "react";
import { FaClipboard, FaShareAlt } from "react-icons/fa";
import './ShareCarpool.css';  // Import CSS (see below)

interface ShareCarpoolProps {
  carpoolId: string;
}

const ShareCarpool: React.FC<ShareCarpoolProps> = ({ carpoolId }) => {
  // const BASE_URL = `https://carpoolschedule.com/carpool/`;
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const handleCopyId = () => {
    navigator.clipboard.writeText(carpoolId);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);  // Reset message after 2 seconds
  };

  const handleMouseLeave = () => {
    setIsPopupVisible(false);  // Close popup when mouse leaves the box
  };

  return (
    <div className="share-carpool">
      <button
        className="icon-button"
        onClick={() => setIsPopupVisible((prev) => !prev)}
      >
        <FaShareAlt />
      </button>

      {isPopupVisible && (
        <div
          className="copy-popup"
          ref={popupRef}
          onMouseLeave={handleMouseLeave}
        >
          <p>Copy the carpool id below and send it to other families to invite them to your carpool.</p>
          <div className="link-box">
            <input
              type="text"
              value={carpoolId}
              readOnly
              className="link-input"
            />
            <button className="copy-button" onClick={handleCopyId}>
              <FaClipboard />
            </button>
          </div>
          {copied && <span className="copied-message">Copied!</span>}
        </div>
      )}
    </div>
  );
};

export default ShareCarpool;
