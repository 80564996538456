import { call, takeEvery, all, put, select } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { fetchInitialDataAction, fetchInitialDataActionFormat, leaveFamilyAction, leaveFamilyActionFormat, signInAction, signOutAction, updateCarpoolsPartOfAction, updateCarpoolsPartOfActionFormat, updateUserDisplayNameAction, updateUserDisplayNameActionFormat, leaveCarpoolAction, leaveCarpoolActionFormat } from './userActions';
import { getCreatedApp } from '../../firebase';
import { Auth, GoogleAuthProvider, UserCredential, getAuth, signInWithPopup } from 'firebase/auth';
import { setError } from '../global/globalSlice';
import { addHistoryToDb, addNewUserToDb, addUserToNewCarpool, fetchUserData, removeCarpoolPartOfInUserDb, removeFamilyPartOfInUserDb, removeMemberFromCarpoolDb, removeMemberFromFamilyInDb, setUserDisplayNameDb } from '../../dbQueries';
import { addCarpoolsPartOf, removeCarpoolPartOf, removeFamilyPartOf, selectFamilyPartOfInCarpool, setCarpoolsPartOf, setUserDisplayName, setUserId } from './userSlice';
import { FamilyPartOf, HistoryItem, Member, userSliceState } from '../storeStates';
import { fetchInitialCarpoolDataAction } from '../carpools/carpoolsActions';
import { addItemToHistory, removeMemberFromCarpool, removeMemberFromFamily } from '../carpools/carpoolsSlice';

function* signOut() {
  try {
    const auth: Auth = yield call(getAuth, getCreatedApp());
    auth.signOut();
    new Cookies().remove("userId");
    yield put(setUserId(null));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* signIn() {
  try {
    const provider = new GoogleAuthProvider();
    const auth = getAuth(getCreatedApp());
    const result: UserCredential = yield call(signInWithPopup, auth, provider);
    yield call(addNewUserToDb, "" + result.user.uid, {carpoolsPartOf: [], displayName: "" + result.user.displayName});
    new Cookies().set("userId", "" + result.user.uid);
    yield put(setUserId("" + result.user.uid));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* leaveFamily(action: leaveFamilyActionFormat) {
  try {
    const {carpoolId, userId, userDisplayName} = action.payload;
    const family: FamilyPartOf | null | undefined = yield select(selectFamilyPartOfInCarpool(carpoolId));

    if (family) {
      // remove in store
      yield put(removeFamilyPartOf({carpoolId}));
      // remove in db
      yield call(removeFamilyPartOfInUserDb, userId, carpoolId);

      // remove user from members list of given family if they are part of a family
      // in db
      yield call(removeMemberFromFamilyInDb, carpoolId, family.familyId, {userId, displayName: userDisplayName});
      // in store
      yield put(removeMemberFromFamily({carpoolId, familyId: family.familyId, userId}));

      // add history action in db
      const historyItem: HistoryItem = {userId, userDisplayName, action: `${userDisplayName} left ${family?.familyName} family`};
      yield call(addHistoryToDb, carpoolId, historyItem);
      // add history action in store
      yield put(addItemToHistory({carpoolId, ...historyItem}));
    }
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }

}

function* leaveCarpool(action: leaveCarpoolActionFormat) {
  try {
    const {carpoolId, userId, userDisplayName} = action.payload;
    const family: FamilyPartOf | null | undefined = yield select(selectFamilyPartOfInCarpool(carpoolId));

    const member: Member = {userId, displayName: userDisplayName};
    // remove member from allMembers in carpool db and carpoolPartOf in user db
    yield call(removeMemberFromCarpoolDb, carpoolId, member);
    yield call(removeCarpoolPartOfInUserDb, carpoolId, userId);
    // remove in store
    yield put(removeMemberFromCarpool({carpoolId}));
    yield put(removeCarpoolPartOf({carpoolId}));

    if (family) {
      // remove user from given family/members if they are part of a family
      // in db
      yield call(removeMemberFromFamilyInDb, carpoolId, family.familyId, member);
      // in store
      yield put(removeMemberFromFamily({carpoolId, familyId: family.familyId, userId}));
    }

    // add history action in db
    const historyItem: HistoryItem = {userId, userDisplayName, action: `${userDisplayName} left this carpool`};
    yield call(addHistoryToDb, carpoolId, historyItem);
    // add history action in store
    yield put(addItemToHistory({carpoolId, ...historyItem}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* updateUserDisplayName(action: updateUserDisplayNameActionFormat) {
  try {
    const {displayName, userId} = action.payload;
    yield put(setUserDisplayName(displayName));
    yield call(setUserDisplayNameDb, userId, displayName);
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* fetchInitialData(action: fetchInitialDataActionFormat) {
  try {
    const { userId } = action.payload;
    const data: userSliceState = yield call(fetchUserData, userId);
    yield put(setCarpoolsPartOf(data.carpoolsPartOf));
    yield put(setUserDisplayName(data.displayName));
    yield put(setUserId(userId));
    yield put(fetchInitialCarpoolDataAction({userId}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* updateCarpoolsPartOf(action: updateCarpoolsPartOfActionFormat) {
  try {
    const { carpoolName, carpoolId, userId } = action.payload;
    // update carpoolsPartOf in user db
    yield call(addUserToNewCarpool, carpoolId, userId, carpoolName);
    // add to userSlice in store as well
    yield put(addCarpoolsPartOf({carpoolId, carpoolName, familyPartOf: null}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(signOutAction.type, signOut),
    takeEvery(signInAction.type, signIn),
    takeEvery(fetchInitialDataAction.type, fetchInitialData),
    takeEvery(updateCarpoolsPartOfAction.type, updateCarpoolsPartOf),
    takeEvery(updateUserDisplayNameAction.type, updateUserDisplayName),
    takeEvery(leaveFamilyAction.type, leaveFamily),
    takeEvery(leaveCarpoolAction.type, leaveCarpool),
  ]);
}