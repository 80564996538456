import { v4 as uuidv4 } from 'uuid';
import { Family, FamilyPartOf } from '../store/storeStates';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserDisplayName, selectUserId } from '../store/user/userSlice';
import { joinFamilyAction } from '../store/carpools/carpoolsActions';
import { useMemo } from 'react';
import { leaveFamilyAction } from '../store/user/userActions';

function ViewFamily(props: {family: Family, turns: number, carpoolId: string, familyPartOf: FamilyPartOf | null | undefined, isYourFamily: boolean}) {
  const { family, carpoolId, familyPartOf } = props;
  const userId = useSelector(selectUserId) || "";
  const displayName = useSelector(selectUserDisplayName) || "";
  const dispatch = useDispatch();

  const handleJoinFamily = async () => {
    dispatch(joinFamilyAction({
      carpoolId,
      familyId: family.familyId,
      userId,
      familyName: family.familyName,
      newMember: {displayName, userId},
      historyItem: {userId, userDisplayName: displayName, action: `${displayName} joined ${family.familyName} family`},
    }));
  }

  const userHasFamily: boolean = useMemo(() => !!familyPartOf, [familyPartOf]);

  const handleLeaveFamily = () => {
    dispatch(leaveFamilyAction({carpoolId, userDisplayName: displayName, userId}));
  }

  return (
    <div>
      <details style={{marginTop: '20px'}}>
        <summary>
          {props.isYourFamily && "(You)"} {family.familyName} (has {props.turns} turns in total this year)
          {props.isYourFamily && <span className='hyperlink' style={{marginTop: "5px", marginLeft: "5px"}} onClick={handleLeaveFamily}>Exit family</span>}
          {!userHasFamily && <span className='hyperlink' style={{marginTop: "5px", marginLeft: "5px"}} onClick={handleJoinFamily}>Enter family</span>}
          </summary>
        <p>Members: {family.members.length === 0 && 'No one'}</p>
        <ul>
          {family.members.map((member) => (
            <li key={uuidv4()}>{member.displayName}</li>
          ))}
        </ul>
        <p>Vacation Days</p>
        <ul>
          {family.vacationDays.map((day) => (
            <li key={uuidv4()}>{day}</li>
          ))}
        </ul>
        <p>Days Available</p>
        <ul>
          {family.daysAvailable.map((day) => (
            <li key={uuidv4()}>{day}</li>
          ))}
        </ul>
      </details>
    </div>
  );
}

export default ViewFamily;