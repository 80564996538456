function ConfirmationPopup (props: { confirmFunc: any, cancelFunc: any, confirmText: string }) {
  return (
    <div className="popup">
      <div className="popup-inner">
        <h2>{props.confirmText}</h2>
        <button className='secondary-btn' onClick={props.confirmFunc}>Yes</button>
        <button style={{marginLeft: '10px'}} className='secondary-btn' onClick={props.cancelFunc}>No</button>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
