import './Legend.css';
import { allEventTypeIcons, Family, FamilyColors } from '../store/storeStates';


const Legend = (props: {families: Family[], familyColors: FamilyColors, setShowFamilyForm: (show: boolean) => void}) => {

  let colorsForLegend: { [name: string]: string } = {
    'No school': '#ef233c', // red
    'Other': '#6BADCE', // medium blue
  };

  let familyColorsForLegend: { [name: string]: string } = {};
  for (let family of props.families) {
    if (props.familyColors[family.familyId]) {
      familyColorsForLegend[family.familyName + ' Family'] = props.familyColors[family.familyId];
    }
  }

  let iconsForLegend: { [name: string]: string } = {'Dropoff Only': allEventTypeIcons['dropoff'], 'Pickup Only': allEventTypeIcons['pickup'], 'Round-trip': allEventTypeIcons['round-trip']};


  return (
    <div className="legend-container">
      <p>Events</p>
      {Object.entries(colorsForLegend).map(([name, color]) => (
        <div key={name} className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: color }}
          ></span>
          <span className="legend-label">{name}</span>
        </div>
      ))}
      <p>Families</p>
      {Object.entries(familyColorsForLegend).map(([name, color]) => (
        <div key={name} className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: color }}
          ></span>
          <span className="legend-label">{name}</span>
        </div>
      ))}
      <span onClick={() => props.families.length < 5 && props.setShowFamilyForm(true)} className={props.families.length === 5 ? 'disable-add-family' : 'add-family-link'}>Add a family</span>
      <p>Driving types</p>
      {Object.entries(iconsForLegend).map(([name, icon]) => (
        <div key={name} className="legend-item">
          <span
            className="legend-color"
          >{icon}</span>
          <span className="legend-label">{name}</span>
        </div>
      ))}
    </div>
  );
};

export default Legend;