import { createSlice } from "@reduxjs/toolkit";
import { allSlicesState, globalSliceState } from "../storeStates";


const initialState: globalSliceState = {
  error: null,
  loading: false,
}

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setToLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
  }
});

export const { setError, setToLoading, stopLoading } = globalSlice.actions;

export const selectLoading = (state: allSlicesState) => state.global.loading;
export const selectError = (state: allSlicesState) => state.global.error;

export default globalSlice.reducer;