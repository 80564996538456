import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { requestAccessAction } from "../store/carpools/carpoolsActions";
import { selectCarpoolsPartOf, selectUserDisplayName, selectUserId } from "../store/user/userSlice";
import './Home.css'
import './CarpoolsList.css';
import { setError } from "../store/global/globalSlice";
import { allowedToRequestAccess } from "../dbQueries";
import ViewCarpool from "./ViewCarpool";
import { CarpoolPartOfState } from "../store/storeStates";

export default function Home() {
  const navigate = useNavigate();
  const [joinId, setJoinId] = useState<string>("");
  const [accessRequested, setAccessRequested] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId) || "";
  const userDisplayName = useSelector(selectUserDisplayName) || "";
  const carpoolsPartOf = useSelector(selectCarpoolsPartOf);

  const [carpoolToDisplay, setCarpoolToDisplay] = useState<string | null>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (accessRequested) {
      timer = setTimeout(() => {
        setAccessRequested(false);
      }, 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [accessRequested]);

  const handleCreateCarpool = () => {
    navigate('/create-carpool');
  }

  const requestMembership = async () => {
    const carpoolId = joinId;
    const allowedToRequest = await allowedToRequestAccess(carpoolId, userId, carpoolsPartOf);
    if (allowedToRequest.error) {
      dispatch(setError(allowedToRequest.error));
    } else if (allowedToRequest.issue) {
      alert(allowedToRequest.issue);
      setJoinId("");
    } else {
      dispatch(requestAccessAction({carpoolId, person: {userId, userDisplayName}}));
      setAccessRequested(true);
      setJoinId("");
    }
  }

  useEffect(() => {
    if (carpoolsPartOf.length > 0) {
      setCarpoolToDisplay(carpoolsPartOf[0].carpoolId);
    }
  }, [carpoolsPartOf]);

  if (carpoolToDisplay && carpoolsPartOf.length > 0) {
    return (
      <div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '10px', paddingTop: '10px', paddingRight: '10px'}}>
          <AllCarpoolsList carpoolsPartOf={carpoolsPartOf} setCarpoolToDisplay={setCarpoolToDisplay} />
          <button className='primary-btn' onClick={handleCreateCarpool}>Create carpool</button>
          <div id="join-carpool-container">
            <p>Join Existing Carpool:</p>
            <input type="text" onChange={(e) => setJoinId(e.target.value)} placeholder="Put carpool id"/>
            <button className='secondary-btn' id="create-carpool-btn" disabled={!joinId.trim()} onClick={requestMembership}>Send</button>
            {accessRequested && <p className={"requested-msg"}>Sent!</p>}
          </div>
        </div>
        <ViewCarpool carpoolId={carpoolToDisplay}/>
      </div>
    );
  } else {
    return (
      <div className="dashboard">
        <p>You are not part of any carpools yet!</p>
        <button className='primary-btn' onClick={handleCreateCarpool}>Create carpool</button>
          <div id="join-carpool-container">
            <p>Join Existing Carpool:</p>
            <input type="text" onChange={(e) => setJoinId(e.target.value)} placeholder="Put carpool id"/>
            <button className='secondary-btn' id="create-carpool-btn" disabled={!joinId.trim()} onClick={requestMembership}>Send</button>
            {accessRequested && <p className={"requested-msg"}>Sent!</p>}
          </div>
      </div>
    )
  }
}


function AllCarpoolsList(props: {carpoolsPartOf: CarpoolPartOfState[], setCarpoolToDisplay: (carpoolId: string) => void}) {

  return (
    <div style={{display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', justifyContent: 'center'}}>
      <p>All Carpools:</p>
      <select onChange={(e) => props.setCarpoolToDisplay(e.target.value)}>
        {props.carpoolsPartOf.map((carpoolPartOf) => (
        <option key={carpoolPartOf.carpoolId} value={carpoolPartOf.carpoolId}>
          {carpoolPartOf.carpoolName}
        </option>
        ))}
      </select>
    </div>
  );
}