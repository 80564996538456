import { useDispatch, useSelector } from "react-redux";
import { selectCarpoolById } from "../store/carpools/carpoolsSlice";
import { CarpoolInfo, Family, FamilyPartOf } from "../store/storeStates";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import AddFamilyForm from "./AddFamilyForm";
import RequestAccess from "./RequestAccess";
import PageNotFound from "./PageNotFound";
import ViewFamily from "./ViewFamily";
import { carpoolExistsInDb } from "../dbQueries";
import { createRotationAction, manualUpdatesAction } from "../store/carpools/carpoolsActions";
import { selectFamilyPartOfInCarpool } from "../store/user/userSlice";
import './ViewCarpool.css';
import ConfirmationPopup from "./ConfirmationPopup";
import RenderSchedule from "./RenderSchedule";
import { selectError } from "../store/global/globalSlice";
import ShareCarpool from "./ShareCarpool";

function CarpoolCalendarView(props: {carpoolId: string}) {
  const { carpoolId } = props;
  const error: string | null = useSelector(selectError);
  // undefined if not loaded, null if that carpool was not found in store
  const carpool: CarpoolInfo | undefined | null = useSelector(selectCarpoolById(carpoolId));
  const familyPartOf: FamilyPartOf | null | undefined = useSelector(selectFamilyPartOfInCarpool(carpoolId));
  const [showFamilyForm, setShowFamilyForm] = useState<boolean>(false);
  const [carpoolExists, setCarpoolExists] = useState<boolean>(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const dispatch = useDispatch();

  const displayFamilies = (currCarpool: CarpoolInfo) => {
    const families: Family[] = currCarpool.families;
    if (families.length === 0) {
      return <p>No families in this carpool yet!</p>;
    }

    return (
      <>
        <p className="center">Families</p>
        {families.map((family) => <ViewFamily key={uuidv4()} isYourFamily={familyPartOf?.familyId === family.familyId} turns={currCarpool.turnsCount[family.familyId]} family={family} carpoolId={carpoolId} familyPartOf={familyPartOf}/>)}
      </>
    );
  }

  const createRotation = () => {
    dispatch(createRotationAction(carpoolId));
    setShowConfirmPopup(false);
  }

  const onManualUpdatesClick = () => {
    console.log('started');
    dispatch(manualUpdatesAction({carpoolId}));
    console.log('finished');
  }

  useEffect(() => {
    const checkAndSetExists = async () => {
      const exists = await carpoolExistsInDb(carpoolId);
      setCarpoolExists(exists);
    }
    checkAndSetExists();
  }, [carpoolId]);

  return (
    <div>
      {error && <h1 style={{color: 'red'}}>There was an error! Please reload the page and try again.</h1>}
      {/*if carpool is undefined, that means store data hasn't loaded yet show loading */}
      {carpool === undefined && <h1>Loading...</h1>}
      {/* if !carpoolExists, no such carpool is in DB (therefore carpool === null as well) */}
      {carpool === null && !carpoolExists && <PageNotFound />}
      {/* if carpool === null but carpoolExists in db, then user is not member so needs to request access */}
      {carpool === null && carpoolExists && <RequestAccess carpoolId={carpoolId}/>}
      {/* if carpool has a value (not null or undefined) and carpoolExists, then user has access to view it */}
      {carpool && carpoolExists && (
        <>
          <div className="title-row">
            <h1>{carpool.carpoolName}</h1>
            <ShareCarpool carpoolId={carpoolId}/>
          </div>
          <p>Tip: click on a calendar day or the plus icon below to create an event</p>
          {/* <button className='primary-btn' onClick={onManualUpdatesClick}>Manual Updates (Hidden, DO NOT CLICK)</button> */}
          {!familyPartOf && <p>You're not part of a family yet! Create a family or join an existing one below.</p>}
          <RenderSchedule
            schedule={carpool.schedule}
            schoolStart={new Date(carpool.schoolStart + "T00:00:00")}
            schoolEnd={new Date(carpool.schoolEnd + "T00:00:00")}
            setShowFamilyForm={setShowFamilyForm}
            families={carpool.families}
            familyPartOf={familyPartOf || null}
            carpoolId={carpoolId}
            setShowConfirmPopup={setShowConfirmPopup}
          />
          
          {showConfirmPopup && <ConfirmationPopup confirmFunc={createRotation} confirmText={"Are you sure? Creating a rotation will reset the entire calendar and create a new dropoff/pickup schedule."} cancelFunc={() => setShowConfirmPopup(false)}/>}
          {showFamilyForm && <AddFamilyForm setShowFamilyForm={setShowFamilyForm} shouldJoinFamily={familyPartOf === null || familyPartOf === undefined} carpoolId={carpoolId}/>}
          {displayFamilies(carpool)}
        </>
      )}
    </div>
  );
}
export default CarpoolCalendarView;