import { useDispatch, useSelector } from "react-redux";
import { signInAction, signOutAction, updateUserDisplayNameAction } from "../store/user/userActions";
import { selectUserDisplayName, selectUserId } from "../store/user/userSlice";
import { useEffect } from "react";
import "./Auth.css";
import { useNavigate } from "react-router";

function Auth(props: {isLoggedIn: string | null, setIsLoggedIn: (userId: string | null) => void, redirectUrl: string | null}) {
  const { isLoggedIn, setIsLoggedIn, redirectUrl } = props;
  const userId: string | null = useSelector(selectUserId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDisplayName: string = useSelector(selectUserDisplayName) || "";

  const signIn = () => {
    dispatch(signInAction());
  }

  const signOut = () => {
    dispatch(signOutAction());
  }

  const updateDisplayName = (val: string) => {
    if (!userId) return;
    if (!val.trim()) return;
    dispatch(updateUserDisplayNameAction({userId, displayName: val.trim()}));
  }

  useEffect(() => {
    setIsLoggedIn(userId);
    if (userId && redirectUrl) navigate(redirectUrl);
  }, [navigate, redirectUrl, setIsLoggedIn, userId]);

  return (
    <div className="container">
      {!isLoggedIn ?
        <>
          <h1>You must be logged in to manage your carpools!</h1>
          <button className='primary-btn' id="signin-google-button" onClick={signIn}>Sign In With Google</button>
        </>
        :
        <>
          <label htmlFor="displayName">Display Name:</label>
          <input type="text" id="display-name-input" placeholder={userDisplayName} onChange={(e) => updateDisplayName(e.target.value)}/>
          <button className='primary-btn' id="signout-btn" onClick={signOut}>Sign out</button>
      </>
    }
    </div>
  );
}

export default Auth;