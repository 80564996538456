import { useState, useMemo } from 'react';
import { AllEventTypes, CustomEvent, Family, validCarpoolTypes } from '../store/storeStates';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface EventModalProps {
  event: CustomEvent | null;
  newEvent: boolean;
  families: Family[];
  onSave: (eventData: CustomEvent) => void;
  onDelete: () => void;
  onClose: () => void;
}

const EventModal: React.FC<EventModalProps> = ({ event, families, onSave, onDelete, onClose, newEvent }) => {
  
  const realEventInitialDate: Date = useMemo(() => {
    return event ? event.start : new Date();
  }, [event]);
  
  const [eventType, setEventType] = useState<AllEventTypes>(event?.eventType.type || 'other');
  const [familyId, setFamilyId] = useState<string | null>(event?.eventType.familyId || null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(realEventInitialDate);
  const [title, setTitle] = useState(event?.title || '');
  const [description, setDescription] = useState(event?.description || '');
  // const [recurringDays, setRecurringDays] = useState<RecurringDays>(event.recurring?.days || [0, 0, 0, 0, 0, 0, 0]);
  // const [recurEndDate, setRecurEndDate] = useState<string | null>(event.recurring?.end || null);

  const handleSave = () => {
    if (!selectedDate) return;
    console.log(familyId);
    let actualDate = new Date(selectedDate);
    const updatedEvent: CustomEvent = {
      eventType: {type: eventType, familyId: familyId && validCarpoolTypes.includes(familyId) ? familyId : null},
      title,
      description,
      start: actualDate,
      end: actualDate,
      id: event?.id || null,
      recurring: event?.recurring || null,
    };
    onSave(updatedEvent);
  };

  const handleFamilyIdChange = (evt: any) => {
    setFamilyId(evt.target.value);
    if (!title) {
      const familyName = evt.target.selectedOptions[0].text;
      if (eventType === 'dropoff') {
        setTitle('Dropoff: ' + familyName);
      } else if (eventType === 'pickup') {
        setTitle('Pickup: ' + familyName);
      } else if (eventType === 'round-trip') {
        setTitle('Round-trip: ' + familyName);
      }
    }
  }

  const handleEventChange = (evt: any) => {
    setEventType(evt.target.value as AllEventTypes);
    if (!title) {
      if (eventType === 'no-school') {
        setTitle('No school ');
      }
    }
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{newEvent ? "Create" : "Edit"} Event</h2>
        <div className="event-form">
          <div className="form-row">
            <label>Date: </label>
            <DatePicker
              selected={selectedDate}
              onChange={(date: Date | null) => setSelectedDate(date)}
              dateFormat="MM-dd-yyyy"  // Fixed the date format
              required
              placeholderText="Click to select a date"
              className="input"
            />
          </div>
          <div className="form-row">
            <label>Type: </label>
            <select
              className="input"
              value={eventType}
              onChange={(e) => handleEventChange(e)}
            >
              <option value="dropoff">Dropoff</option>
              <option value="pickup">Pickup</option>
              <option value="round-trip">Round-trip</option>
              <option value="no-school">No School</option>
              <option value="other">Other</option>
            </select>
          </div>
          {['dropoff', 'pickup', 'round-trip'].includes(eventType) && (
            <div className="form-row">
              <label>Family: </label>
              <select
                className="input"
                required
                value={familyId || ''}
                onChange={(e) => handleFamilyIdChange(e)}
              >
                <option value="" disabled>Select a family</option>
                {families.map(family => (
                  <option key={family.familyId} value={family.familyId}>
                    {family.familyName}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="form-row">
          <label>Title: </label>
          <input
            type="text"
            className="input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          </div>
          <div className="form-row">
          <label>Description: </label>
          <input
            type="text"
            className="input"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div style={{display: 'flex'}}>
          <button className='primary-btn' disabled={!title || !eventType || ((eventType === 'round-trip' || eventType === 'dropoff' || eventType === 'pickup') && !familyId)} onClick={handleSave}>Save</button>
          {event && <button className='secondary-btn' style={{marginLeft: '5px'}} onClick={onDelete}>Delete</button>}
          <button className='secondary-btn' style={{marginLeft: '5px'}} onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default EventModal;