// src/sagas/rootSaga.ts
import { all } from 'redux-saga/effects';
import userSaga from './user/userSaga';
import carpoolsSaga from './carpools/carpoolsSaga';

export default function* rootSaga() {
  yield all([
    userSaga(),
    carpoolsSaga(),
  ]);
}
