import { useState } from "react";
import AddFamilyFormItems from "./AddFamilyFormItems";
import { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDisplayName, selectUserId } from "../store/user/userSlice";
import { FamilyDbState, allColors } from '../store/storeStates';
import { addFamilyAction } from "../store/carpools/carpoolsActions";
import './ConfirmationPopup.css';
import { selectAllFamiliesInCarpool } from "../store/carpools/carpoolsSlice";

function AddFamilyForm(props: {setShowFamilyForm: (b: boolean) => void, carpoolId: string, shouldJoinFamily: boolean}) {
  const dispatch = useDispatch();
  const families = useSelector(selectAllFamiliesInCarpool(props.carpoolId));

  const [familyName, setFamilyName] = useState<string>('');
  const [vacationDays, setVacationDays] = useState<DateObject[]>([]);
  const [daysAvailable, setDaysAvailable] = useState<string[]>([]);

  const userId = useSelector(selectUserId) || "";
  const userDisplayName = useSelector(selectUserDisplayName) || "";

  const handleVacationDaysChange = (dates: DateObject[]) => {
    setVacationDays(dates);
  };

  const checkIfFamilyNameUnique = (familyName: string) => {
    return families.every((family) => family.familyName !== familyName);
  }

  const handleDaysAvailableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setDaysAvailable(selectedOptions);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!checkIfFamilyNameUnique(familyName)) {
      alert("Family name must be unique in this carpool!");
      return;
    }
    const newFamily: FamilyDbState = {
      familyName,
      vacationDays: vacationDays.map((date) => date.format("YYYY-MM-DD")),
      daysAvailable,
      members: props.shouldJoinFamily ? [{userId, displayName: userDisplayName}] : [],
    };
    dispatch(addFamilyAction({carpoolId: props.carpoolId, color: allColors[families.length], newFamily, userId, userDisplayName, shouldJoinFamily: props.shouldJoinFamily}));
    props.setShowFamilyForm(false);
  }

  return (
    <div className="popup">
      <form className="popup-inner" onSubmit={handleSubmit}>
        <button className='primary-btn' onClick={() => props.setShowFamilyForm(false)}>X</button>
        <AddFamilyFormItems
          familyName={familyName}
          setFamilyName={setFamilyName}
          daysAvailable={daysAvailable}
          vacationDays={vacationDays}
          handleDaysAvailableChange={handleDaysAvailableChange}
          handleVacationDaysChange={handleVacationDaysChange}
        />
        <button className='primary-btn' type="submit" disabled={familyName.trim() === ""}>Submit</button>
      </form>
    </div>
  );
}

export default AddFamilyForm;