import { useState } from "react";
import CarpoolCalendarView from "./CarpoolCalendarView";
import CarpoolInfoView from "./CarpoolInfoView";
import CarpoolViewsNavbar from "./CarpoolViewsNavbar";
import { selectRequestingAccess } from "../store/carpools/carpoolsSlice";
import { useSelector } from "react-redux";
import { RequestingAccessPerson } from "../store/storeStates";


const ViewCarpool = (props: {carpoolId: string}) => {
  const [view, setView] = useState<'calendar' | 'info'>('calendar');
  const requestingAccess: RequestingAccessPerson[] | undefined = useSelector(selectRequestingAccess(props.carpoolId));

  return (
    <div>
      <CarpoolViewsNavbar view={view} setView={setView} showNotify={!!requestingAccess && requestingAccess.length > 0}/>
      {view === 'calendar' ? <CarpoolCalendarView carpoolId={props.carpoolId}/> : <CarpoolInfoView carpoolId={props.carpoolId}/>}
    </div>
  );
}

export default ViewCarpool;