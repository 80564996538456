import { DocumentReference, DocumentSnapshot, addDoc, arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import { CarpoolInfo, Family, CarpoolPartOfState, HistoryItem, CarpoolDbState, Member, FamilyDbState, UserDbState, RequestingAccessPerson, ScheduleEvent, ScheduleEventType, TurnsCount, validCarpoolTypes, FamilyColors } from './store/storeStates';
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';

// fetch functions

/**
 * fetches given user's data from database
 * @param userId id of logged-in user
 * @returns data about user
 * @throws error if user not found in database
 * @throws error if any other error occurs while fetching user data
 */
export const fetchUserData = async (userId: string) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const { displayName, carpoolsPartOf } = docSnapshot.data();
      return { displayName, carpoolsPartOf, userId };
    } else {
      throw new Error("User not found in database");
    }
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const addVacationDaysToFamilyDb = (carpoolId: string, familyId: string, dates: string[]) => {
  try {
    const cpDocRef = doc(db, "carpools", carpoolId);
    const familyColRef = doc(cpDocRef, "families", familyId);
    updateDoc(familyColRef, {vacationDays: arrayUnion(...dates)});
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

/**
 * fetches all families data for given carpool from database
 * @param cpDocRef reference to a carpool document in database
 * @returns families list of families in the given carpool
 * @throws error if expected family was not found in families collection
 * @throws error if any other error occurs while fetching families data
 */
export const fetchFamiliesData = async (cpDocRef: DocumentReference) => {
  try {
    const familiesCollectionRef = collection(cpDocRef, "families");
    const familiesDocsSnaps = await getDocs(familiesCollectionRef);
    let families: Family[] = [];
    familiesDocsSnaps.forEach((familyDocSnap) => {
      if (familyDocSnap.exists()) {
        const {familyName, vacationDays, daysAvailable, members} = familyDocSnap.data();
        const family: Family = {familyId: familyDocSnap.id, familyName, vacationDays, daysAvailable, members};
        families.push(family);
      } else {
        throw new Error("Family not found in database");
      }
    })
    return families;
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

/**
 * fetches all carpool data for given user from database
 * @param userId
 * @param carpoolsPartOf list of carpools the user is part of if already fetched, otherwise not given
 * @returns all carpools' info that user is part of
 * @throws error if carpool not found in database
 * @throws error if any other error occurs while fetching carpool data
 */
export const fetchUsersCarpoolData = async (userId: string, carpoolsPartOf?: CarpoolPartOfState[]) => {
  try {
    let relevantCarpools = (carpoolsPartOf === undefined) ? (await fetchUserData(userId)).carpoolsPartOf : carpoolsPartOf;
    let allCarpoolInfo: CarpoolInfo[] = [];
    for (const carpool of relevantCarpools) {
      const cpDocRef = doc(db, "carpools", carpool.carpoolId);
      const cpDocSnap = await getDoc(cpDocRef);
      if (cpDocSnap.exists()) {
        const {carpoolName, requestingAccess, turnsCount, schoolStart, familyColors, schoolEnd, schoolHolidays, history, schedule, allMembers} = cpDocSnap.data();
        const families: Family[] = await fetchFamiliesData(cpDocRef);
        const carpoolInfo: CarpoolInfo = {carpoolId: carpool.carpoolId, familyColors, turnsCount, requestingAccess, allMembers, carpoolName, schoolStart, schoolEnd, schoolHolidays, schedule, history, families};
        allCarpoolInfo.push(carpoolInfo);
      } else {
        throw new Error("Carpool not found in database");
      }
    }
    return allCarpoolInfo;
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

// update user collection functions

/**
 * adds new user to database if not already present
 * @param userId
 * @param newUser user info to add to database
 * @throws error if any error occurs while adding user to database
 */
export const addNewUserToDb = async (userId: string, newUser: UserDbState) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnapshot = await getDoc(docRef);
    if (!docSnapshot.exists()) {
      await setDoc(docRef, newUser);
    }
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const setUserDisplayNameDb = async (userId: string, displayName: string) => {
  try {
    const docRef = doc(db, "users", userId);
    await updateDoc(docRef, {displayName});
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

/**
 * updates carpoolsPartOf field in user document in database with a new carpool, but not in any specific family
 * @param carpoolId id of carpool to add user to
 * @param userId id of user
 * @param carpoolName name of carpool to add user to
 * @throws error if user not found in database
 * @throws error if any other error occurs while updating user data
 */
export const addUserToNewCarpool = async (carpoolId: string, userId: string, carpoolName: string) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      await updateDoc(userDocRef, {
        carpoolsPartOf: arrayUnion({carpoolId, carpoolName, familyPartOf: null})
      });
    } else {
      throw new Error("User not found in database");
    }
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const removeCarpoolPartOfInUserDb = async (carpoolId: string, userId: string) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      const targetCarpool = userDocSnapshot.data().carpoolsPartOf.find((carpool: CarpoolPartOfState) => carpool.carpoolId === carpoolId);
      if (!targetCarpool) {
        throw new Error("Given carpool not found in user's carpoolsPartOf");
      }
      await updateDoc(userDocRef, {
        carpoolsPartOf: arrayRemove(targetCarpool)
      });
    } else {
      throw new Error("User not found in database");
    }
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

/**
 * updates familyPartOf field in user document in database with a new family, assumes user is already member of given carpool (just not in a specific family)
 * @param userId
 * @param carpoolId
 * @param familyId
 * @param familyName
 * @throws error if user not found in database
 * @throws error if any other error occurs while updating user data
 */
export const updateFamilyPartOfInUserDb = async (userId: string, carpoolId: string, familyId: string, familyName: string) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      const carpoolsPartOf = userDocSnapshot.data().carpoolsPartOf;
      const updatedCarpoolsPartOf = carpoolsPartOf.map((carpool: CarpoolPartOfState) => {
        if (carpool.carpoolId === carpoolId) {
          return {
            ...carpool,
            familyPartOf: {familyId, familyName},
          };
        }
        return carpool;
      });
      await updateDoc(userDocRef, {carpoolsPartOf: updatedCarpoolsPartOf});
    } else {
      throw new Error("User not found in database");
    }
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const removeFamilyPartOfInUserDb = async (userId: string, carpoolId: string) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      const carpoolsPartOf = userDocSnapshot.data().carpoolsPartOf;
      const updatedCarpoolsPartOf = carpoolsPartOf.map((carpool: CarpoolPartOfState) => {
        if (carpool.carpoolId === carpoolId) {
          return {
            ...carpool,
            familyPartOf: null,
          };
        }
        return carpool;
      });
      await updateDoc(userDocRef, {carpoolsPartOf: updatedCarpoolsPartOf});
    } else {
      throw new Error("User not found in database");
    }
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

// update carpool collection functions

/**
 * adds history item to carpool document in database
 * @param carpoolId
 * @param historyItem
 * @param carpoolDocRef reference to carpool document in database if already fetched, otherwise not given
 * @throws error if any error occurs while adding history item to database
 */
export const addHistoryToDb = async (carpoolId: string, historyItem: HistoryItem, carpoolDocRef?: DocumentReference) => {
  try {
    let relevantCarpoolRef: DocumentReference = (carpoolDocRef === undefined) ? doc(db, "carpools", carpoolId) : carpoolDocRef;
    const docSnapshot = await getDoc(relevantCarpoolRef);
    if (!docSnapshot.exists()) {
      throw new Error("Carpool not found in database");
    }
    const { history } = docSnapshot.data();
    const newHistory = [historyItem, ...history];
    await updateDoc(relevantCarpoolRef, {history: newHistory});
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

/**
 * adds a new family to carpool document in database
 * @param carpoolId
 * @param newFamily family data to add to database (everything except for a familyId, as it is not known at this point)
 * @returns carpoolDocRef: reference to carpool document in database, familyId: familyId of newly added family
 * @throws error if any error occurs while adding family to database
 */
export const addFamilyToDb = async (carpoolId: string, newFamily: FamilyDbState) => {
  try {
    const docRef = doc(db, "carpools", carpoolId);
    const familySubColRef = collection(docRef, "families");
    const familyDocRef: DocumentReference = await addDoc(familySubColRef, newFamily);
    return { carpoolDocRef: docRef, familyId: familyDocRef.id };
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const addFamilyTurnsCountToDb = async (carpoolId: string, familyId: string) => {
  try {
    const carpoolDocRef = doc(db, "carpools", carpoolId);
    const carpoolDocSnapshot = await getDoc(carpoolDocRef);
    if (carpoolDocSnapshot.exists()) {
      const turnsCount = carpoolDocSnapshot.data().turnsCount;
      turnsCount[familyId] = 0;
      await updateDoc(carpoolDocRef, { turnsCount });
    } else {
      throw new Error("Carpool not found in database");
    }
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

/**
 * adds or removes person from requestingAccess field in carpool document in database
 * @param add whether to add or remove person from requestingAccess field
 * @param carpoolId
 * @param person data about person that is requesting access to the carpool
 * @throws error if any error occurs while updating requestingAccess field in database
 */
export const setRequestingAccessPersonInDb = async (add: boolean, carpoolId: string, person: RequestingAccessPerson) => {
  try {
    const carpoolDocRef = doc(db, "carpools", carpoolId);
    await updateDoc(carpoolDocRef, { requestingAccess: (add ? arrayUnion(person) : arrayRemove(person)) });
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

/**
 * adds new member to carpool document in database
 * @param carpoolId
 * @param newMember the new member to add to a carpool
 * @returns carpoolDocRef reference to carpool document in database
 * @throws error if any error occurs while adding new member to database
 */
export const addNewMemberToCarpool = async (carpoolId: string, newMember: Member) => {
  try {
    const carpoolDocRef = doc(db, "carpools", carpoolId);
    await updateDoc(carpoolDocRef, {
      allMembers: arrayUnion(newMember),
    });
    return carpoolDocRef;
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const setTurnsCountInDb = async (carpoolId: string, turnsCount: TurnsCount) => {
  try {
    const carpoolDocRef = doc(db, "carpools", carpoolId);
    await updateDoc(carpoolDocRef, {turnsCount});
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const removeMemberFromCarpoolDb = async (carpoolId: string, member: Member) => {
  try {
    const carpoolDocRef = doc(db, "carpools", carpoolId);
    await updateDoc(carpoolDocRef, {
      allMembers: arrayRemove(member),
    });
    return carpoolDocRef;
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

// update family collection functions

/**
 * adds new member to given family in given carpool in database
 * @param carpoolId
 * @param familyId
 * @param newMember new member to add to a family
 * @throws error if any error occurs while adding new family member to database
 */
export const addNewMemberToFamilyInDb = async (carpoolId: string, familyId: string, newMember: Member) => {
  try {
    const cpDocRef = doc(db, "carpools", carpoolId);
    const familyColRef = doc(cpDocRef, "families", familyId);
    await updateDoc(familyColRef, {members: arrayUnion(newMember)});
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const removeMemberFromFamilyInDb = async (carpoolId: string, familyId: string, member: Member) => {
  try {
    const cpDocRef = doc(db, "carpools", carpoolId);
    const familyColRef = doc(cpDocRef, "families", familyId);
    await updateDoc(familyColRef, {members: arrayRemove(member)});
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

// general db update functions

/**
 * adds new carpool to database
 * @param newCarpool carpool data to add to database (everything except for a carpoolId, as it is not known at this point)
 * @returns docRef reference to new carpool document in database
 * @throws error if any error occurs while adding new carpool to database
 */
export const addNewCarpoolToDb = async (newCarpool: CarpoolDbState) => {
  try {
    const carpoolsCollectionRef = collection(db, "carpools");
    const docRef: DocumentReference = await addDoc(carpoolsCollectionRef, newCarpool);
    return docRef;
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

// getter and checking functions

/**
 * gets email of logged-in user
 * @param userId
 * @returns email of user
 * @throws error if logged-in user was not found
 * @throws error if relevant data for logged-in user was not found
 * @throws error if email was not set for logged-in user
 */
export const getUserEmail = (userId: string) => {
  try {
    const user = getAuth().currentUser;
    if (!user) {
      throw new Error("couldn't find logged in user");
    }
    const targetUserProvider = user.providerData.find(provider => provider.uid === userId);
    if (!targetUserProvider) {
      throw new Error("couldn't find relevant data for logged in user");
    }
    if (!targetUserProvider.email) {
      throw new Error("Email wasn't set for logged in user");
    }
    return targetUserProvider.email;
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

/**
 * checks if a carpool with a given carpoolId exists in database
 * @param carpoolId
 * @returns true/false for whether a carpool with a given carpoolId exists in database
 * @throws error if any error occurs while checking if carpool exists in database
 */
export const carpoolExistsInDb = async (carpoolId: string) => {
  try {
    const carpoolDocRef: DocumentReference = doc(db, "carpools", carpoolId);
    const docSnapshot: DocumentSnapshot = await getDoc(carpoolDocRef);
    return docSnapshot.exists();
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

const getTurnDay = (dayToFamilies: string[][]) => {
  const dayToLengths = dayToFamilies.map((day) => day.length);
  const turnDayIdx = dayToLengths.indexOf(Math.max(...dayToLengths));
  return turnDayIdx;
}

const createDefaultObj = (families: Family[]) => {
  const obj: { [familyId: string]: number } = {};
  for (const family of families) {
    obj[family.familyId] = 0;
  }
  return obj;
}

const generalWeeklySchedule = (families: Family[], turnDayIdx: number, dayToFamilies: string[][]) => {
  // get general weekly schedule except for turn day
  let numTurnsInWeek = createDefaultObj(families);
  let weekAssignment: string[] = new Array(5).fill("");

  for (let i = 0; i < 5; i++) {
    if (i === turnDayIdx) continue;
    // find an available family who has had the least number of turns thus far
    const availableFamilies: string[] = dayToFamilies[i];
    let minTurns = 8;
    let minTurnsFamilyId = "";
    for (const familyId of availableFamilies) {
      if (numTurnsInWeek[familyId] < minTurns) {
        minTurns = numTurnsInWeek[familyId];
        minTurnsFamilyId = familyId;
      }
    }
    // assign that family that day
    weekAssignment[i] = minTurnsFamilyId;
    numTurnsInWeek[minTurnsFamilyId]++;
  }
  return weekAssignment;
}

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export const generateYearlySchedule = (origSchedule: ScheduleEvent[], startDay: string, endDay: string, schoolHolidays: string[], families: Family[]) => {
  try {
    // idx = day of week, value = list of familyIds available that day
    const dayToFamilies = invertAvailabilities(families);
    // idx of day most families are available
    const turnDayIdx = getTurnDay(dayToFamilies);
    // general weekly schedule for families except for turn day
    const weekAssignment: string[] = generalWeeklySchedule(families, turnDayIdx, dayToFamilies);
    let yearlyTurnsCt = createDefaultObj(families);

    const schedule: ScheduleEvent[] = actuallyCreateSchedule(origSchedule, yearlyTurnsCt, families, dayToFamilies, weekAssignment, turnDayIdx, new Date(startDay+"T00:00:00"), new Date(endDay+"T00:00:00"), schoolHolidays);
    return {transpSchedule: schedule, yearlyTurnsCt};
  } catch (error: any) {
    console.error(error.toString());
    return {transpSchedule: [], yearlyTurnsCt: {}};
  }
}

export const updateTransportSchedule = (schedule: ScheduleEvent[], origSchedule: ScheduleEvent[]) => {
  let updatedSchedule = origSchedule.filter(evt => !validCarpoolTypes.includes(evt.type.type));
  updatedSchedule = [...updatedSchedule, ...schedule];
  return updatedSchedule;
}

export const setScheduleInCarpoolDb = async (carpoolId: string, schedule: ScheduleEvent[]) => {
  try {
    const carpoolDocRef = doc(db, "carpools", carpoolId);
    await updateDoc(carpoolDocRef, {schedule});
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const manualHolidayUpdates = (schedule: ScheduleEvent[], schoolHolidays: string[]) => {
  let updatedSchedule: ScheduleEvent[] = [...schedule];
  for (const date of schoolHolidays) {
    const type: ScheduleEventType = {type: 'no-school', familyId: null};
    updatedSchedule.push({date, title: 'Holiday, No School', description: '', time: null, type, id: uuidv4(), recurring: null});
  }
  return updatedSchedule;
}

export const manualDrivingUpdates = (schedule: ScheduleEvent[], turnsCount: TurnsCount) => {
  let updatedSchedule: ScheduleEvent[] = [...schedule];
  let updatedTurnsCount: TurnsCount = {...turnsCount};
  let date = new Date();
  date.setDate(date.getDate() - 1);
  for (let i = 1; i <= 37; i++) {
    let familyId = "";
    let familyName = "";
    if (i % 3 === 1) {
      familyId = "ayaywDlWVmhHuQhS2SjP";
      familyName = "Devesh";
    } else if (i % 3 === 2) {
      familyId = "gzR3A908sqfFrVwRkaP2";
      familyName = "Daniel";
    } else {
      familyId = "08uNIkdL71epEWZPFcrE";
      familyName = "Rishaan";
    }
    for (let j = 1; j <= 5; j++) {
      const type: ScheduleEventType = {type: 'round-trip', familyId};
      updatedSchedule.push({date: date.toISOString().split('T')[0], title: 'Round-trip: ' + familyName, description: '', time: null, type, id: uuidv4(), recurring: null});
      date.setDate(date.getDate() + 1);
      updatedTurnsCount[familyId]++;
    }
    // weekend increment
    date.setDate(date.getDate() + 1);
    date.setDate(date.getDate() + 1);

  }
  return {updatedSchedule, updatedTurnsCount};
}

const actuallyCreateSchedule = (origSchedule: ScheduleEvent[], yearlyTurnsCt: TurnsCount, families: Family[], dayToFamilies: string[][], weekAssignment: string[], turnDayIdx: number, startDay: Date, endDay: Date, schoolHolidays: string[]) => {
  const turnDayFamilies: string[] = dayToFamilies[turnDayIdx];
  let ptr = 0;
  let schedule: ScheduleEvent[] = [];
  let noSchoolEvts: ScheduleEvent[] = origSchedule.filter((evt: ScheduleEvent) => evt.type.type === "no-school");
  let noSchoolDates: string[] = noSchoolEvts.map((evt) => evt.date);
  for (let i = startDay; i <= endDay; i.setDate(i.getDate() + 1)) {
    const day: number = i.getDay(); // from 0 to 6
    const relYear = i.getFullYear();
    const relMonth = String(i.getMonth() + 1).padStart(2, "0");
    const relDay = String(i.getDate()).padStart(2, "0");
    const date = `${relYear}-${relMonth}-${relDay}`; // YYYY-MM-DD date

    if (day === 0 || day === 6 || noSchoolDates.includes(date)) {
      continue;
    } else if (schoolHolidays.includes(date)) {
      const type: ScheduleEventType = {type: 'no-school', familyId: null};
      schedule.push({date, title: 'Holiday, No School', description: '', time: null, type, id: uuidv4(), recurring: null});
      continue;
    } else if (day - 1 === turnDayIdx) {
      const turnFamilyId = getTurnFamilyId(turnDayFamilies[ptr], dayToFamilies[day - 1], families, date, yearlyTurnsCt);
      if (turnFamilyId) {
        const type: ScheduleEventType = {type: 'round-trip', familyId: turnFamilyId};
        const targetFamily = families.find((family) => family.familyId === turnFamilyId);
        if (!targetFamily) {
          throw new Error("target family not found in families array when turn day");
        }
        schedule.push({date, title: 'Round-trip: ' + targetFamily.familyName, description: '', time: null, type, id: uuidv4(), recurring: null});
        yearlyTurnsCt[turnFamilyId]++;
        if (turnFamilyId === turnDayFamilies[ptr]) {
          ptr = (ptr + 1) % turnDayFamilies.length;
        }
      }
    } else {
      if (weekAssignment[day - 1] === "") {
        continue;
      }
      const turnFamilyId = getTurnFamilyId(weekAssignment[day - 1], dayToFamilies[day - 1], families, date, yearlyTurnsCt);
      if (turnFamilyId) {
        const type: ScheduleEventType = {type: 'round-trip', familyId: turnFamilyId};
        const targetFamily = families.find((family) => family.familyId === turnFamilyId);
        if (!targetFamily) {
          throw new Error("target family not found in families array when not turn day");
        }
        schedule.push({date, title: 'Round-trip: ' + targetFamily.familyName, description: '', time: null, type, id: uuidv4(), recurring: null});
        yearlyTurnsCt[turnFamilyId]++;
      }
    }
  }
  for (const family of families) {
    for (const vacationDay of family.vacationDays) {
      const type: ScheduleEventType = { type: 'other', familyId: null };
      schedule.push({
        date: vacationDay,
        title: `${family.familyName} is on vacation`,
        description: '',
        time: null,
        type,
        id: uuidv4(),
        recurring: null,
      });
    }
  }

  return schedule;
}

export const addFamilyColorToDb = async (carpoolId: string, familyId: string, color: string) => {
  try {
    const carpoolDocRef = doc(db, "carpools", carpoolId);
    const carpoolDocSnapshot = await getDoc(carpoolDocRef);
    if (carpoolDocSnapshot.exists()) {
      let familyColors: FamilyColors = carpoolDocSnapshot.data().familyColors;
      familyColors[familyId] = color;
      await updateDoc(carpoolDocRef, { familyColors });
    } else {
      throw new Error("Carpool not found in database");
    }
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

const getTurnFamilyId = (origTurnFamilyId: string, availFamsOnRelevantDay: string[], families: Family[], date: string, yearlyTurnsCt: TurnsCount) => {
  // considering vacation day of the orig family whose turn it is

  const turnFamily = families.find((family) => family.familyId === origTurnFamilyId);
  if (!turnFamily) {
    throw new Error("Family not found in families array");
  }
  if (turnFamily.vacationDays.includes(date)) {
    // find a family that is available that day (dayToFamilies) and does not have a vacation that day; return that
    let possibleFamilies = availFamsOnRelevantDay.filter((familyId) => {
      const checkFamily = families.find((family) => family.familyId === familyId);
      if (!checkFamily) {
        throw new Error("Family not found in families array when finding alternative");
      }
      return !checkFamily.vacationDays.includes(date);
    });
    if (possibleFamilies.length === 0) return null;
    let minCt = yearlyTurnsCt[possibleFamilies[0]];
    let optFamilyId = possibleFamilies[0];
    for (const familyId of possibleFamilies) {
      if (yearlyTurnsCt[familyId] < minCt) {
        minCt = yearlyTurnsCt[familyId];
        optFamilyId = familyId;
      }
    }
    return optFamilyId;
  } else {
    return origTurnFamilyId;
  }
}

const invertAvailabilities = (families: Family[]) => {
  const inverseArray: string[][] = [[],[],[],[],[]];
  for (const family of families) {
    for (const day of family.daysAvailable) {
      const index = daysOfWeek.indexOf(day);
      inverseArray[index].push(family.familyId);
    }
  }
  return inverseArray;
}

export const getPeopleRequestingAccess = async (carpoolId: string) => {
  try {
    const carpoolDocRef = doc(db, "carpools", carpoolId);
    const docSnapshot = await getDoc(carpoolDocRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data().requestingAccess;
    } else {
      throw new Error("Carpool not found in database");
    }
  } catch (error: any) {
    throw new Error(error.toString());
  }
}

export const allowedToRequestAccess = async (carpoolId: string, userId: string, carpoolsPartOf: CarpoolPartOfState[]) => {
  if (!carpoolId) {
    return {issue: "Invalid url!", error: null};
  }
  if (carpoolsPartOf.find((carpool) => carpool.carpoolId === carpoolId)) {
    return {issue: "You are already a member of this carpool!", error: null};
  }
  try {
    const requestingAccess = await getPeopleRequestingAccess(carpoolId);
    if (requestingAccess.find((person: RequestingAccessPerson) => person.userId === userId)) {
      return {issue: "You already requested access to this carpool!", error: null};
    }
    return {issue: null, error: null};
  } catch (error) {
    return {issue: null, error};
  }
}