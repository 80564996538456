import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { addCarpool, addFamilyInfoToCarpool, addFamilyMemberToFamily, addFamilyTurnCount, addItemToHistory, addMemberToCarpool, addToFamilyColors, removeAccessRequester, selectCarpoolById, selectCarpoolSchedule, selectTurnsCount, setCarpools, setScheduleInCarpool, setTurnsCount } from "./carpoolsSlice";
import { addFamilyColorToDb, addFamilyToDb, addFamilyTurnsCountToDb, addHistoryToDb, addNewCarpoolToDb, addNewMemberToCarpool, addNewMemberToFamilyInDb, addUserToNewCarpool, fetchUsersCarpoolData, generateYearlySchedule, manualDrivingUpdates, manualHolidayUpdates, setRequestingAccessPersonInDb, setScheduleInCarpoolDb, setTurnsCountInDb, updateFamilyPartOfInUserDb, updateTransportSchedule } from "../../dbQueries";
import { CarpoolDbState, CarpoolInfo, HistoryItem, Member, ScheduleEvent, TurnsCount } from '../storeStates';
import { addFamilyAction, addFamilyActionFormat, addNewCarpoolAction, addNewCarpoolActionFormat, addScheduleEventAction, addScheduleEventActionFormat, createRotationAction, createRotationActionFormat, deleteScheduleEventAction, deleteScheduleEventActionFormat, editScheduleEventAction, editScheduleEventActionFormat, fetchInitialCarpoolDataAction, fetchInitialCarpoolDataActionFormat, grantAccessAction, grantAccessActionFormat, joinFamilyAction, joinFamilyActionFormat, manualUpdatesAction, manualUpdatesActionFormat, removeRequestAction, removeRequestActionFormat, requestAccessAction, requestAccessActionFormat, updateTurnsCountAction, updateTurnsCountActionFormat } from "./carpoolsActions";
import { setError } from "../global/globalSlice";
import { addFamilyToCarpool, updateFamilyPartOf } from "../user/userSlice";
import { DocumentReference } from "firebase/firestore";
import { updateCarpoolsPartOfAction } from "../user/userActions";

function* fetchInitialCarpoolData(action: fetchInitialCarpoolDataActionFormat) {
  try {
    const { userId } = action.payload;
    const carpools: CarpoolInfo[] = yield call(fetchUsersCarpoolData, userId);
    yield put(setCarpools(carpools));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* addFamily(action: addFamilyActionFormat) {
  try {
    const { carpoolId, color, newFamily, userId, userDisplayName, shouldJoinFamily } = action.payload;
    // add family to carpool db
    const { carpoolDocRef, familyId} = yield call(addFamilyToDb, carpoolId, newFamily);
    let actionStr = `Added family ${newFamily.familyName}${shouldJoinFamily ? ` with first member ${userDisplayName}` : ''}`;
    const historyItem: HistoryItem = {userDisplayName, action: actionStr, userId};
    // add history item to carpool db
    yield call(addHistoryToDb, carpoolId, historyItem, carpoolDocRef);
    if (shouldJoinFamily) {
      // update familyPartOf in users db
      yield call(updateFamilyPartOfInUserDb, userId, carpoolId, familyId, newFamily.familyName);
    }
    // update turnsCount in carpool db
    yield call(addFamilyTurnsCountToDb, carpoolId, familyId);
    // update familyColors in carpool db
    yield call(addFamilyColorToDb, carpoolId, familyId, color);

    // update redux state
    if (shouldJoinFamily) {
      yield put(addFamilyToCarpool({carpoolId, familyId, familyName: newFamily.familyName}));
    }
    yield put(addFamilyInfoToCarpool({
      carpoolId,
      familyId,
      ...newFamily,
      userId,
      displayName: userDisplayName
    }));
    yield put(addItemToHistory({carpoolId, ...historyItem}));
    yield put (addFamilyTurnCount({carpoolId, familyId}));
    yield put(addToFamilyColors({carpoolId, familyId, color}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* manualUpdates(action: manualUpdatesActionFormat) {
  try {
    const { carpoolId } = action.payload;
    const schedule: ScheduleEvent[] = yield select(selectCarpoolSchedule(carpoolId));
    const carpoolInfo: CarpoolInfo = yield select(selectCarpoolById(carpoolId));
    const schoolHolidays: string[] = carpoolInfo.schoolHolidays;
    const turnsCount: TurnsCount = carpoolInfo.turnsCount;
    const updatedHolidays: ScheduleEvent[] = manualHolidayUpdates(schedule, schoolHolidays);
    const {updatedSchedule, updatedTurnsCount} = manualDrivingUpdates(updatedHolidays, turnsCount);
    yield call(setScheduleInCarpoolDb, carpoolId, updatedSchedule);
    yield put(setScheduleInCarpool({carpoolId, schedule: updatedSchedule}));
    yield call(setTurnsCountInDb, carpoolId, updatedTurnsCount);
    yield put(setTurnsCount({carpoolId, turnsCount: updatedTurnsCount}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* removeRequest(action: removeRequestActionFormat) {
  try {
    const { carpoolId, person } = action.payload;
    yield call(setRequestingAccessPersonInDb, false, carpoolId, person);
    yield put(removeAccessRequester({carpoolId, requestingAccessPerson: person}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* grantAccess(action: grantAccessActionFormat) {
  try {
    const { carpoolId, person, userId, userDisplayName, carpoolName } = action.payload;
    // remove person from requesters list
    yield put(removeRequestAction({carpoolId, person}));
    // add new member to carpool in db
    const newMember: Member = {userId: person.userId, displayName: person.userDisplayName};
    const cpDocRef: DocumentReference = yield call(addNewMemberToCarpool, carpoolId, newMember);
    // add history item to carpool in db
    const historyItem: HistoryItem = {action: `added member ${person.userDisplayName}`, userId, userDisplayName};
    yield call(addHistoryToDb, carpoolId, historyItem, cpDocRef);

    // update carpoolsPartOf to user in db
    yield call(addUserToNewCarpool, carpoolId, person.userId, carpoolName);

    // add member and history item to carpool in redux store
    yield put(addMemberToCarpool({carpoolId, member: newMember}));
    yield put(addItemToHistory({carpoolId, ...historyItem}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* requestAccess(action: requestAccessActionFormat) {
  try {
    yield call(setRequestingAccessPersonInDb, true, action.payload.carpoolId, action.payload.person);
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* joinFamily(action: joinFamilyActionFormat) {
  try {
    const { userId, carpoolId, familyId, familyName, newMember, historyItem } = action.payload;
    // add new member to family in db
    yield call(addNewMemberToFamilyInDb, carpoolId, familyId, newMember);
    // add history item to carpool in db
    yield call(addHistoryToDb, carpoolId, historyItem);
    // update familyPartOf in given carpoolPartOf in user db
    yield call(updateFamilyPartOfInUserDb, userId, carpoolId, familyId, familyName);

    // update carpool.families in store with new member in family
    yield put(addFamilyMemberToFamily({carpoolId, familyId, member: newMember}));
    // update user in store with new familyPartOf
    yield put(updateFamilyPartOf({familyId, familyName, carpoolId}));
    // add history action in store
    yield put(addItemToHistory({carpoolId, ...historyItem}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

export const addNewCarpoolToDbAndGetId = async (newCarpool: CarpoolDbState) => {
  const cpDocRef: DocumentReference = await addNewCarpoolToDb(newCarpool);
  return cpDocRef;
}

function* addNewCarpool(action: addNewCarpoolActionFormat) {
  try {
    const { newCarpool, userId, userDisplayName, addedToDbCarpoolId } = action.payload;
    // add new carpool to db
    let carpoolId;
    if (addedToDbCarpoolId) {
      carpoolId = addedToDbCarpoolId;
    } else {
      const cpDocRef: DocumentReference = yield call(addNewCarpoolToDbAndGetId, newCarpool);
      carpoolId = cpDocRef.id;
    }
    // add to carpoolsSlice in store as well
    yield put(addCarpool({
      ...newCarpool,
      carpoolId,
      families: [],
      allMembers: [{userId, displayName: userDisplayName}],
    }));
    yield put(updateCarpoolsPartOfAction({carpoolName: newCarpool.carpoolName, carpoolId, userId}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* updateTurnsCount(action: updateTurnsCountActionFormat) {
  try {
    const { carpoolId, newEvt, origEvt } = action.payload;
    if ((newEvt && newEvt.type.familyId) || (origEvt && origEvt.type.familyId)) {
      let turnsCount: TurnsCount = yield select(selectTurnsCount(carpoolId));
      let newTurnsCount: TurnsCount = {...turnsCount};
      if (newEvt && newEvt.type.familyId) {
        if (newEvt.type.type === 'round-trip') {
          newTurnsCount[newEvt.type.familyId]++;
        } else {
          newTurnsCount[newEvt.type.familyId] += 0.5;
        }
      }
      if (origEvt && origEvt.type.familyId) {
        if (origEvt.type.type === 'round-trip') {
          newTurnsCount[origEvt.type.familyId]--;
        } else {
          newTurnsCount[origEvt.type.familyId] -= 0.5;
        }
      }
      yield call(setTurnsCountInDb, carpoolId, newTurnsCount);
      yield put(setTurnsCount({carpoolId, turnsCount: newTurnsCount}));
    }
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}


function* addScheduleEvent(action: addScheduleEventActionFormat) {
  try {
    const { carpoolId, newEvent } = action.payload;
    const schedule: ScheduleEvent[] = yield select(selectCarpoolSchedule(carpoolId));
    const updatedSchedule: ScheduleEvent[] = [...schedule, newEvent];
    yield call(setScheduleInCarpoolDb, carpoolId, updatedSchedule);
    yield put(setScheduleInCarpool({carpoolId, schedule: updatedSchedule}));

    // increment turnsCount
    yield put(updateTurnsCountAction({carpoolId, newEvt: newEvent, origEvt: null}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* editScheduleEvent(action: editScheduleEventActionFormat) {
  try {
    const { carpoolId, newEvent } = action.payload;
    const schedule: ScheduleEvent[] = yield select(selectCarpoolSchedule(carpoolId));
    const origEvt = schedule.find(evt => evt.id === newEvent.id);
    if (!origEvt) {
      throw new Error('Event not found in schedule');
    }

    // increment turnsCount for new event and decrement for orig event, if required
    yield put(updateTurnsCountAction({carpoolId, newEvt: newEvent, origEvt}));
    let updatedSchedule: ScheduleEvent[] = schedule.filter(evt => evt.id !== newEvent.id);
    updatedSchedule.push(newEvent);
    yield put(setScheduleInCarpool({carpoolId, schedule: updatedSchedule}));
    yield call(setScheduleInCarpoolDb, carpoolId, updatedSchedule);
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}

function* deleteScheduleEvent(action: deleteScheduleEventActionFormat) {
  try {
    const { carpoolId, id } = action.payload;
    const schedule: ScheduleEvent[] = yield select(selectCarpoolSchedule(carpoolId));
    const origEvt = schedule.find(evt => evt.id === id);
    if (!origEvt) {
      throw new Error('Event not found in schedule');
    }

    // decrement turnsCount for orig event, if required
    yield put(updateTurnsCountAction({carpoolId, newEvt: null, origEvt}));

    const updatedSchedule: ScheduleEvent[] = schedule.filter((evt) => evt.id !== id);
    yield call(setScheduleInCarpoolDb, carpoolId, updatedSchedule);
    yield put(setScheduleInCarpool({carpoolId, schedule: updatedSchedule}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }
}


function* createRotation(action: createRotationActionFormat) {
  try {
    const carpoolId: string = action.payload;
    const carpoolInfo: CarpoolInfo = yield select(selectCarpoolById(carpoolId));
    const { families, schoolStart, schoolEnd, schoolHolidays, schedule } = carpoolInfo;
    const {transpSchedule, yearlyTurnsCt} = yield call(generateYearlySchedule, schedule, schoolStart, schoolEnd, schoolHolidays, families);
    const updatedSchedule: ScheduleEvent[] = yield call(updateTransportSchedule, transpSchedule, schedule);
    yield call(setScheduleInCarpoolDb, carpoolId, updatedSchedule);
    yield call(setTurnsCountInDb, carpoolId, yearlyTurnsCt);
    // put new schedule in store as well
    yield put(setScheduleInCarpool({carpoolId, schedule: updatedSchedule}));
    // update yearlyTurnsCt in store as well
    yield put(setTurnsCount({carpoolId, turnsCount: yearlyTurnsCt}));
  } catch (error: any) {
    console.error(error);
    yield put(setError(error.toString()));
  }

  // replace schedule field in db with new schedule
  // get carpoolInfo based on carpoolId
  // if every family is available every day, then each family gets the whole week.
  // END
  // choose turn day = day where max number of families are available
  // x = num of families available on turn day
  // const numTurnsWeek = {familyId: 0, familyId: 0, familyId: 0, familyId: 0}
  // const weekAssignment = {Monday: null, Tuesday: null, Wednesday: null, Thursday: null, Friday: null}
  // loop through day=Monday -> Friday except turn day
    // get all families available on day; get family with least amount of turns used and assign that family in weekAssignment obj
    // increment that family's turns in numTurnsWeek arr
  // END

  // now to create actual schedule
  // totalYearTurns = {familyId: 0, familyId: 0, ..., familyId}
  // availableTurnDaysFamilies arr = [familyId1, familyId2, ..., familyId x]
  // ptr = 0
  // for date=startDate; date <= endDate; date++:
      // if weekend, add element to schedule w/ weekend=true; continue
      // if holiday, add element to schedule w/ holiday=true; continue
      // if not turnDay, add element to schedule w/ familyId=weekAssignment[date.day]
      // if turnDay, add element to schedule w/ familyId=families[ptr]
            // ptr = (ptr + 1) % x;
      // if not weekend or holiday, update totalYearTurns[familyId]++
}

export default function* carpoolsSaga() {
  yield all([
    takeEvery(fetchInitialCarpoolDataAction.type, fetchInitialCarpoolData),
    takeEvery(addFamilyAction.type, addFamily),
    takeEvery(removeRequestAction.type, removeRequest),
    takeEvery(grantAccessAction.type, grantAccess),
    takeEvery(requestAccessAction.type, requestAccess),
    takeEvery(joinFamilyAction.type, joinFamily),
    takeEvery(addNewCarpoolAction.type, addNewCarpool),
    takeEvery(createRotationAction.type, createRotation),
    takeEvery(addScheduleEventAction.type, addScheduleEvent),
    takeEvery(editScheduleEventAction.type, editScheduleEvent),
    takeEvery(deleteScheduleEventAction.type, deleteScheduleEvent),
    takeEvery(updateTurnsCountAction.type, updateTurnsCount),
    takeEvery(manualUpdatesAction.type, manualUpdates),
  ]);
}