import React, { useState } from 'react';
import MultiDatePicker, { DateObject } from 'react-multi-date-picker';
import { selectUserDisplayName, selectUserId } from '../store/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCarpoolAction } from '../store/carpools/carpoolsActions';
import './CreateCarpool.css'
import { useNavigate } from 'react-router';
import { addNewCarpoolToDbAndGetId } from '../store/carpools/carpoolsSaga';
import { CarpoolDbState } from '../store/storeStates';
function CreateCarpool() {
  const [carpoolName, setCarpoolName] = useState(`${new Date().getFullYear()}-carpool`);
  const [startDay, setStartDay] = useState<string>(`${new Date().getFullYear()}-08-03`);
  const [endDay, setEndDay] = useState<string>(`${new Date().getFullYear() + 1}-06-20`);
  const [schoolHolidays, setSchoolHolidays] = useState<DateObject[]>([]);
  const userDisplayName = useSelector(selectUserDisplayName) || "";
  const userId = useSelector(selectUserId) || "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await addNewCarpool();
    navigate(`/`);
  };

  const addNewCarpool = async () => {
    const newCarpool: CarpoolDbState = {
      schedule: [],
      requestingAccess: [],
      carpoolName,
      turnsCount: {},
      familyColors: {},
      schoolStart: startDay,
      schoolEnd: endDay,
      schoolHolidays: schoolHolidays.map((date) => date.format("YYYY-MM-DD")),
      history: [{action: "created carpool", userId, userDisplayName}],
      allMembers: [{userId, displayName: userDisplayName}],
    };
    const cpDocRef = await addNewCarpoolToDbAndGetId(newCarpool);
    dispatch(addNewCarpoolAction({
      userId,
      userDisplayName,
      newCarpool,
      addedToDbCarpoolId: cpDocRef.id,
    }));
    return cpDocRef.id;
  }

  const handleHolidayDateChange = (dates: DateObject[]) => {
    setSchoolHolidays(dates);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>Carpool Name:</p>
      <input type="text" required value={carpoolName} onChange={(e) => setCarpoolName(e.target.value)} />

      <p>Start Day:</p>
      <input type="date"
        value={startDay}
        required
        onChange={(e) => setStartDay(e.target.value)}
      />

      <p>End Day:</p>
      <input type="date"
        value={endDay}
        required
        onChange={(e) => setEndDay(e.target.value)}
      />

      <p>School holidays: </p>
      <MultiDatePicker
        multiple
        value={schoolHolidays}
        onChange={handleHolidayDateChange}
        format="YYYY-MM-DD"
      />
      <button className='primary-btn' type="submit">Create Carpool</button>
    </form>
  );
}

export default CreateCarpool;