//require('dotenv').config();

// prod vars
const REACT_APP_API_KEY = "AIzaSyCx_BIF0HBL1-xQt2y5jvX2YYXEUMmF87I"
const REACT_APP_AUTH_DOMAIN = "carpoolschedule-acb44.firebaseapp.com"
const REACT_APP_PROJECT_ID = "carpoolschedule-acb44"
const REACT_APP_STORAGE_BUCKET = "carpoolschedule-acb44.appspot.com"
const REACT_APP_MESSAGING_SENDER_ID = "287072301741"
const REACT_APP_APP_ID = "1:287072301741:web:fa1a1da8a918f51b9e0c24"
const REACT_APP_MEASUREMENT_ID = "G-6LS462Y22V"

// dev vars
// const REACT_APP_API_KEY="AIzaSyBaJkh6TVNR12-_LH9t6ypaL295_8DAjxU"
// const REACT_APP_AUTH_DOMAIN="carpoolschedule-dev.firebaseapp.com"
// const REACT_APP_PROJECT_ID="carpoolschedule-dev"
// const REACT_APP_STORAGE_BUCKET="carpoolschedule-dev.appspot.com"
// const REACT_APP_MESSAGING_SENDER_ID="368076732249"
// const REACT_APP_APP_ID="1:368076732249:web:b62133911727d3ecf73e48"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID, // prod only var
};

export default firebaseConfig;