export interface allSlicesState {
  user: userSliceState;
  carpools: carpoolsSliceState;
  global: globalSliceState;
}

export interface globalSliceState {
  error: string | null;
  loading: boolean;
}

export interface userSliceState extends UserDbState {
  userId: string | null;
}

export interface UserDbState {
  displayName: string | null;
  carpoolsPartOf: CarpoolPartOfState[];
}

export interface CarpoolPartOfState {
  carpoolId: string;
  carpoolName: string;
  familyPartOf: FamilyPartOf | null;
}

export interface FamilyPartOf {
  familyId: string;
  familyName: string;
}

export interface carpoolsSliceState {
  carpools: CarpoolInfo[];
}

export interface CarpoolDbState {
  carpoolName: string;
  requestingAccess: RequestingAccessPerson[];
  schoolStart: string;
  schoolEnd: string;
  schoolHolidays: string[];
  schedule: ScheduleEvent[];
  turnsCount: TurnsCount;
  familyColors: FamilyColors;
  history: HistoryItem[];
  allMembers: Member[];
}

export type TurnsCount = { [familyId: string]: number };
export type FamilyColors = { [familyId: string]: string };
export const allColors: string[] = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'];

export interface CarpoolInfo extends CarpoolDbState {
  carpoolId: string;
  families: Family[];
}

export interface RequestingAccessPerson {
  userId: string;
  userDisplayName: string;
}

export interface ScheduleEvent {
  id: string;
  date: string;
  title: string;
  description: string;
  time: string | null; // should be optional
  type: ScheduleEventType;
  recurring: RecurringInfo | null;
}

export type BinaryVals = 0 | 1;
export type RecurringDays = [BinaryVals, BinaryVals, BinaryVals, BinaryVals, BinaryVals, BinaryVals, BinaryVals];
export interface RecurringInfo {
  groupId: string;
  start: string;
  end: string;
  days: RecurringDays;

}

// Extend react-big-calendar's Event type to include custom fields
export interface CustomEvent {
  id: string | null;
  recurring: RecurringInfo | null;
  start: Date;
  end: Date;
  title: string;
  description: string;
  eventType: ScheduleEventType;
}

export type AllEventTypes = 'dropoff' | 'pickup' | 'round-trip' | 'no-school' | 'other';
export type ScheduleEventType = {type: AllEventTypes; familyId: string | null};
export const allEventTypeIcons: { [eventType: string]: string } = {'dropoff': '🌓', 'pickup': '🌗', 'round-trip': '🌕'};
export const validCarpoolTypes = ['dropoff', 'pickup', 'round-trip'];

export interface HistoryItem {
  action: string;
  userId: string;
  userDisplayName: string;
}

export interface Family extends FamilyDbState {
  familyId: string;
}

export interface FamilyDbState {
  familyName: string;
  vacationDays: string[];
  daysAvailable: string[];
  members: Member[];
}

export interface Member {
  userId: string;
  displayName: string;
}