import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Auth from "./components/Auth";
import Navbar from "./components/Navbar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Home from "./components/Home";
import CreateCarpool from "./components/CreateCarpool";
import PageNotFound from "./components/PageNotFound";
import { fetchInitialDataAction } from "./store/user/userActions";
import Cookies from "universal-cookie";
import ViewCarpool from "./components/ViewCarpool";

function App() {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [isLoggedIn, setIsLoggedIn] = useState<string | null>(cookies.get("userId"));

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchInitialDataAction({ userId: isLoggedIn }));
    }
  }, [isLoggedIn, dispatch]);

  return (
    <BrowserRouter>
      <Navbar userId={isLoggedIn}/>
      <Routes>
        <Route path="/" element={isLoggedIn ? <Home /> : <Auth isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} redirectUrl={null}/>} />
        <Route path="/auth" element={<Auth isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} redirectUrl={null}/>} />
        <Route path="/create-carpool" element={isLoggedIn ? <CreateCarpool /> : <Auth redirectUrl="/create-carpool" isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;