import { useSelector } from "react-redux";
import { selectCarpoolById, selectRequestingAccess } from "../store/carpools/carpoolsSlice";
import { CarpoolInfo, HistoryItem, Member, RequestingAccessPerson } from "../store/storeStates";
import GrantAccessDecision from "./GrantAccessDecision";
import { v4 as uuidv4 } from "uuid";

const CarpoolInfoView = (props: {carpoolId: string}) => {
  const carpool: CarpoolInfo | undefined | null = useSelector(selectCarpoolById(props.carpoolId));
  const requestingAccess: RequestingAccessPerson[] | undefined = useSelector(selectRequestingAccess(props.carpoolId));

  const displayAllRequests = () => {
    if (requestingAccess === undefined) {
      return null;
    }

    return (
      <>
        <p>Requesting Access: {requestingAccess.length === 0 && 'none right now!'}</p>
        
        {requestingAccess.map(
          (request: RequestingAccessPerson) => <GrantAccessDecision key={uuidv4()} person={request} carpoolId={props.carpoolId}/>
        )}
      </>
    );
  }

  const displayAllHistory = () => {
    if (!carpool) {
      return null;
    }
    let history: HistoryItem[] = carpool.history;

    return (
      <div>
        <p>History of All Carpool Actions</p>
        <ul className='scrollable-container'>
          {history.map((item) => <li key={uuidv4()}><p>{item.action}<br></br><em>{item.userDisplayName}</em></p></li>)}
        </ul>
      </div>
    );
  }

  const displayAllMembers = () => {
    if (!carpool) {
      return null;
    }
    let members: Member[] = carpool.allMembers;

    return (
      <div>
        <p>All Members</p>
        <ul className='scrollable-container'>
          {members.map((item: Member) => <li key={uuidv4()}><p>{item.displayName}</p></li>)}
        </ul>
      </div>
    );
  }

  return (
    <div>
      <h1>Carpool Info</h1>
      {displayAllRequests()}
      {displayAllHistory()}
      {displayAllMembers()}
    </div>
  )
}

export default CarpoolInfoView;