import { createAction } from "@reduxjs/toolkit";

export const fetchInitialUserStateAction = createAction('user/fetchInitialUserStateAction');

export interface addUserActionPayload {
    userId: string;
    displayName: string;
}

export interface addUserActionFormat {
    type: string;
    payload: addUserActionPayload
}
export const addUserAction = createAction<addUserActionPayload>('user/addUserAction');

export const signOutAction = createAction('user/signOutAction');

export const signInAction = createAction('user/signInAction');

export const fetchInitialDataAction = createAction<fetchInitialDataActionPayload>('user/fetchInitialDataAction');

export interface fetchInitialDataActionFormat {
    type: string;
    payload: fetchInitialDataActionPayload
}

export interface fetchInitialDataActionPayload {
    userId: string;
}

export interface updateCarpoolsPartOfActionFormat {
    type: string;
    payload: updateCarpoolsPartOfActionPayload;
}

export interface updateCarpoolsPartOfActionPayload {
    carpoolName: string;
    carpoolId: string;
    userId: string;
}

export const updateCarpoolsPartOfAction = createAction<updateCarpoolsPartOfActionPayload>('user/updateCarpoolsPartOfAction');

export interface updateUserDisplayNameActionFormat {
    type: string;
    payload: updateUserDisplayNameActionPayload;
}

export interface updateUserDisplayNameActionPayload {
    userId: string;
    displayName: string;
}

export const updateUserDisplayNameAction = createAction<updateUserDisplayNameActionPayload>('user/updateUserDisplayNameAction');

export interface leaveFamilyActionPayload {
    carpoolId: string;
    userId: string;
    userDisplayName: string;
}

export interface leaveFamilyActionFormat {
    type: string;
    payload: leaveFamilyActionPayload;
}

export const leaveFamilyAction = createAction<leaveFamilyActionPayload>('user/leaveFamilyAction');

export interface leaveCarpoolActionPayload {
    carpoolId: string;
    userId: string;
    userDisplayName: string;
}

export interface leaveCarpoolActionFormat {
    type: string;
    payload: leaveCarpoolActionPayload;
}

export const leaveCarpoolAction = createAction<leaveCarpoolActionPayload>('user/leaveCarpoolAction');