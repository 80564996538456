import { createSlice } from "@reduxjs/toolkit";
import { CarpoolInfo, Member, RequestingAccessPerson, ScheduleEvent, TurnsCount, allSlicesState, carpoolsSliceState } from "../storeStates";

const initialState: carpoolsSliceState = {
  carpools: [],
}

const carpoolsSlice = createSlice({
  name: "carpools",
  initialState,
  reducers: {
    addCarpool(state, action: {type: string; payload: CarpoolInfo}) {
      state.carpools = [...state.carpools, action.payload];
    },
    setCarpools(state, action: {type: string; payload: CarpoolInfo[]}) {
      state.carpools = [...action.payload];
    },
    addToFamilyColors(state, action: {type: string; payload: {carpoolId: string, familyId: string, color: string}}) {
      const currCarpool = state.carpools.find(carpool => carpool.carpoolId === action.payload.carpoolId);
      let familyColors = currCarpool ? currCarpool.familyColors : {};
      familyColors[action.payload.familyId] = action.payload.color;

      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            familyColors,
          };
        }
        return carpool;
      });
    },
    setRequestingAccess(state, action: {type: string; payload: {carpoolId: string, requestingAccessPeople: RequestingAccessPerson[]}}) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            requestingAccess: action.payload.requestingAccessPeople,
          };
        }
        return carpool;
      });
    },
    setTurnsCount(state, action: {type: string; payload: {carpoolId: string, turnsCount: TurnsCount} }) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            turnsCount: action.payload.turnsCount,
          };
        }
        return carpool;
      });
    },
    addFamilyTurnCount(state, action: {type: string; payload: {carpoolId: string, familyId: string}}) {
      // add familyId: 0 to the turnsCount object in the carpool
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            turnsCount: {
              ...carpool.turnsCount,
              [action.payload.familyId]: 0,
            },
          };
        }
        return carpool;
      });
    },
    addRequestingAccess(state, action: {type: string; payload: {carpoolId: string, requestingAccessPerson: RequestingAccessPerson}}) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            requestingAccess: [...carpool.requestingAccess, action.payload.requestingAccessPerson],
          };
        }
        return carpool;
      });
    },
    removeAccessRequester(state, action: {type: string; payload: {carpoolId: string, requestingAccessPerson: RequestingAccessPerson}}) {
      const { carpoolId, requestingAccessPerson } = action.payload;

      const carpool = state.carpools.find(carpool => carpool.carpoolId === carpoolId);
      if (carpool) {
        const newRequestingAccessArr = carpool.requestingAccess.filter(person => person.userId !== requestingAccessPerson.userId);
        state.carpools = state.carpools.map(carpool => {
          if (carpool.carpoolId === carpoolId) {
            return {
              ...carpool,
              requestingAccess: newRequestingAccessArr,
            };
          }
          return carpool;
        });
      } else {
        console.error("Carpool not found in store when rejecting access");
      }
    },
    addFamilyInfoToCarpool(state, action: {type: string; payload: {carpoolId: string, members: Member[], familyId: string, familyName: string, daysAvailable: string[], vacationDays: string[], userId: string, displayName: string}}) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            families: [
              ...carpool.families,
              {
                familyId: action.payload.familyId,
                familyName: action.payload.familyName,
                vacationDays: action.payload.vacationDays,
                daysAvailable: action.payload.daysAvailable,
                members: action.payload.members,
              },
            ],
          };
        }
        return carpool;
      });
    },
    addItemToHistory(state, action: {type: string; payload: {carpoolId: string, action: string, userId: string, userDisplayName: string}}) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            history: [
              {
                action: action.payload.action,
                userId: action.payload.userId,
                userDisplayName: action.payload.userDisplayName,
              },
              ...carpool.history,
            ],
          };
        }
        return carpool;
      });
    },
    addMemberToCarpool(state, action: {type: string; payload: {carpoolId: string, member: Member}}) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            allMembers: [
              ...carpool.allMembers,
              {...action.payload.member},
            ],
          };
        }
        return carpool;
      });
    },
    removeMemberFromCarpool(state, action: {type: string; payload: {carpoolId: string}}) {
      state.carpools = state.carpools.filter((carpool) => {
        return carpool.carpoolId !== action.payload.carpoolId;
      });
    },
    removeMemberFromFamily(state, action: {type: string; payload: {carpoolId: string, familyId: string, userId: string}}) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            families: carpool.families.map((family) => {
              if (family.familyId === action.payload.familyId) {
                return {
                  ...family,
                  members: family.members.filter((member: Member) => (member.userId !== action.payload.userId)),
                };
              }
              return family;
            }),
          };
        }
        return carpool;
      });
    },
    addFamilyMemberToFamily(state, action: {type: string; payload: {carpoolId: string, familyId: string, member: Member}}) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            families: carpool.families.map((family) => {
              if (family.familyId === action.payload.familyId) {
                return {
                  ...family,
                  members: [
                    ...family.members,
                    {...action.payload.member},
                  ],
                };
              }
              return family;
            }),
          };
        }
        return carpool;
      });
    },
    addVacationDaysToFamily(state, action: {type: string; payload: {carpoolId: string, familyId: string, vacationDays: string[]}}) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            families: carpool.families.map((family) => {
              if (family.familyId === action.payload.familyId) {
                return {
                  ...family,
                  vacationDays: [...family.vacationDays, ...action.payload.vacationDays],
                };
              }
              return family;
            }),
          };
        }
        return carpool;
      });
    },
    setScheduleInCarpool(state, action: {type: string; payload: {carpoolId: string, schedule: ScheduleEvent[]}}) {
      state.carpools = state.carpools.map((carpool) => {
        if (carpool.carpoolId === action.payload.carpoolId) {
          return {
            ...carpool,
            schedule: action.payload.schedule,
          };
        }
        return carpool;
      });
    },
  },
});

export const { addVacationDaysToFamily, addToFamilyColors, setTurnsCount, addFamilyTurnCount, removeMemberFromFamily, removeMemberFromCarpool, addCarpool, setScheduleInCarpool, addMemberToCarpool, addFamilyMemberToFamily, removeAccessRequester, addRequestingAccess, setRequestingAccess, setCarpools, addFamilyInfoToCarpool, addItemToHistory } = carpoolsSlice.actions;
export const selectFamilyColors = (carpoolId: string) => (state: allSlicesState) => {
  const found = state.carpools.carpools.find(carpool => carpool.carpoolId === carpoolId);
  return found ? found.familyColors : {};
}
export const selectRequestingAccess = (carpoolId: string) => (state: allSlicesState) => {
  const found = state.carpools.carpools.find(carpool => carpool.carpoolId === carpoolId);
  return found ? found.requestingAccess : undefined;
}
export const selectCarpoolById = (carpoolId: string) => (state: allSlicesState) => {
  const found: CarpoolInfo | undefined = state.carpools.carpools.find(carpool => carpool.carpoolId === carpoolId);
  return found ? found : null;
};

export const selectTurnsCount = (carpoolId: string) => (state: allSlicesState) => {
  const found = state.carpools.carpools.find(carpool => carpool.carpoolId === carpoolId);
  return found ? found.turnsCount : null;
}

export const selectCarpoolNameById = (carpoolId: string) => (state: allSlicesState) => {
  const found: CarpoolInfo | undefined = state.carpools.carpools.find(carpool => carpool.carpoolId === carpoolId);

  return found ? found.carpoolName : undefined;
};

export const selectNumFamiliesInCarpool = (carpoolId: string) => (state: allSlicesState) => {
  const found = state.carpools.carpools.find(carpool => carpool.carpoolId === carpoolId);
  return found ? found.families.length : 0;
}

export const selectCarpoolSchedule = (carpoolId: string) => (state: allSlicesState) => {
  const found = state.carpools.carpools.find(carpool => carpool.carpoolId === carpoolId);
  return found ? found.schedule : null;
}

export const selectFamilyNameById = (carpoolId: string, familyId: string) => (state: allSlicesState) => {
  const found = state.carpools.carpools.find(carpool => carpool.carpoolId === carpoolId);
  if (found) {
    const family = found.families.find(family => family.familyId === familyId);
    return family ? family.familyName : undefined;
  }
  return undefined;
}

export const selectAllFamiliesInCarpool = (carpoolId: string) => (state: allSlicesState) => {
  const found = state.carpools.carpools.find(carpool => carpool.carpoolId === carpoolId);
  return found ? found.families : [];
}

export const selectAllCarpoolInfo = (state: allSlicesState) => state.carpools.carpools;

export default carpoolsSlice.reducer;