import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, Components, momentLocalizer, SlotInfo, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Modal.css';  // Include modal styles here
import { CustomEvent, Family, FamilyPartOf, ScheduleEvent, ScheduleEventType, allEventTypeIcons } from '../store/storeStates';
import { addScheduleEventAction, deleteScheduleEventAction, editScheduleEventAction } from '../store/carpools/carpoolsActions';
import EventModal from './EventModal';
import Legend from './Legend';
import { selectFamilyColors } from '../store/carpools/carpoolsSlice';
import { v4 as uuidv4 } from 'uuid';
import { FaPlus } from 'react-icons/fa';
import StackedWeekView from './StackedWeekView';

interface RenderScheduleProps {
  schedule: ScheduleEvent[];
  schoolStart: Date;
  schoolEnd: Date;
  families: Family[];
  familyPartOf: FamilyPartOf | null;
  carpoolId: string; // global carpoolId prop
  setShowFamilyForm: (show: boolean) => void;
  setShowConfirmPopup: (show: boolean) => void;
}

const localizer = momentLocalizer(moment);

const RenderSchedule: React.FC<RenderScheduleProps> = ({ schedule, setShowConfirmPopup, schoolStart, schoolEnd, setShowFamilyForm, families, familyPartOf, carpoolId }) => {
  const dispatch = useDispatch();
  const familyColors = useSelector(selectFamilyColors(carpoolId));
  const [selectedDayEvents, setSelectedDayEvents] = useState<CustomEvent[]>([]);
  const [isDayModalOpen, setDayModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CustomEvent | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  // Map the schedule prop to Calendar events
  const events: CustomEvent[] = schedule.map((event) => {
    let startDate = new Date(event.date);
    startDate.setDate(startDate.getDate() + 1);
    return {
      start: startDate,
      end: startDate,
      title: event.title,
      description: event.description,
      eventType: {...event.type},
      id: event.id,
      recurring: null,
    }
  });

  // Custom Colors for different event types
  const eventStyleGetter = (event: CustomEvent) => {
    return {
      style: {
        backgroundColor: getColor(event.eventType),
        borderRadius: '5px',
        color: 'white',
        border: 'none',
      },
    };
  };

  const getColor = (eventType: ScheduleEventType) => {
    const familyId: string | null = eventType.familyId;
    const type = eventType.type;
    if (type === 'no-school') {
      // return '#FFA500'; // orange
      return '#ef233c'; // red
    } else if (familyId) {
      return familyColors[familyId];
    } else {
      return '#6BADCE';
      // return '#eae2b7';
    }
  }

  // Open modal for creating new event on selected slot, only if familyPartOf is not null
  const handleSelectSlot = (slotInfo: SlotInfo) => {
    if (familyPartOf) {
      let slotDate = new Date(slotInfo.end);
      slotDate.setDate(slotDate.getDate() - 1);
      setSelectedEvent({
        start: slotDate,
        end: slotDate,
        title: '',
        description: '',
        eventType: {type: 'other', familyId: null},
        id: null,
        recurring: null,
        // index: schedule.length, // Use the length of the schedule array to represent it being added to the end
      });
      setModalOpen(true);  // open modal when a date is clicked
    } else {
      alert('You must enter a family to modify the calendar.');
    }
  };

  // Open modal for editing existing event, only if familyPartOf is not null
  const handleSelectEvent = (event: CustomEvent) => {
    if (familyPartOf) {
      setSelectedEvent({...event});
      setModalOpen(true);  // open modal when an event is clicked
    }
  };

  const handleSave = (eventData: CustomEvent) => {
    if (familyPartOf) {
      const year = eventData.start.getFullYear();
      const month = String(eventData.start.getMonth() + 1).padStart(2, "0");  // Months are 0-based
      const day = String(eventData.start.getDate()).padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;

      const parsableEventData: ScheduleEvent = {id: eventData.id || uuidv4(), time: null, recurring: eventData.recurring, date: dateString, title: eventData.title, description: eventData.description, type: {...eventData.eventType}};
      console.log(parsableEventData.date);
      if (eventData.id) {
        // If editing an existing event, dispatch the edit action
        dispatch(editScheduleEventAction({carpoolId, newEvent: parsableEventData}));
      } else {
        // If adding a new event, dispatch the add action
        dispatch(addScheduleEventAction({carpoolId, newEvent: parsableEventData}));
      }
      setModalOpen(false);
      setDayModalOpen(false);
    }
  };

  const handleDelete = () => {
    if (familyPartOf && selectedEvent && selectedEvent.id) {
      // Dispatch the delete action using the event index
      dispatch(deleteScheduleEventAction({carpoolId, id: selectedEvent.id}));
      setModalOpen(false);
      setDayModalOpen(false);
    }
  };

  const handleShowMore = (eventsForDay: CustomEvent[], day: Date) => {
    setSelectedDayEvents(eventsForDay);
    setDayModalOpen(true);  // open the modal showing all events for the selected day
  };

  // const colors: { [eventType: string]: string } = {
  //   'dropoff': '#FF6347', // tomato red
  //   'pickup': '#FF6347', // tomato red
  //   'round-trip': '#C62D42', // dark red
  //   'no-school': '#FFA500', // orange
  //   'other': '#6BADCE', // blue
  // };

  const appendEmojiToTitle = (title: string, type: string) => {
    return (allEventTypeIcons[type] || '') + ' ' + title;
  };

  return (
    <div>
      <div className="calendar-layout">
        <Calendar
          className="calendar-container"
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          titleAccessor={(event) => appendEmojiToTitle(event.title, event.eventType.type)}
          style={{ height: 500 }}
          selectable={true}  // Disable select if familyPartOf is null
          onSelectSlot={handleSelectSlot}  // Create event when a day is clicked
          onSelectEvent={handleSelectEvent}  // Edit event when an existing event is clicked
          eventPropGetter={eventStyleGetter}
          views={['month']} //
          defaultView="month"
          showMultiDayTimes={false}
          onShowMore={handleShowMore}
        />
          
        <Legend families={families} familyColors={familyColors} setShowFamilyForm={setShowFamilyForm}/>
      </div>
      <div style={{display: 'flex'}}>
        <button disabled={!familyPartOf} className='add-event-btn' onClick={() => {setSelectedEvent(null); setModalOpen(true);}}><FaPlus /></button>
        <button className="create-rotation-btn primary-btn" disabled={families.length === 0} onClick={() => setShowConfirmPopup(true)}>Generate New Schedule</button>
      </div>
      {isDayModalOpen && (
      <div className="modal">
        <div className="modal-content">
          <h2>Events on {selectedDayEvents[0]?.start.toLocaleDateString()}</h2>
          <div className="event-list">
            {selectedDayEvents.map(event => (
              <div
                key={event.id}
                className="event-box"
                style={{ backgroundColor: getColor(event.eventType)}}
                onClick={() => handleSelectEvent(event)}
              >
                <span>{allEventTypeIcons[event.eventType.type] + ' ' || ''}{event.title}</span>
              </div>
            ))}
          </div>
          <button className='primary-btn' onClick={() => setDayModalOpen(false)}>Close</button>
        </div>
      </div>
    )}
      {isModalOpen && (
        <EventModal
          event={selectedEvent}
          newEvent={!selectedEvent || selectedEvent?.id === null}
          families={families}
          onSave={handleSave}
          onDelete={handleDelete}
          onClose={() => {setModalOpen(false); setDayModalOpen(false);}}
        />
      )}
    </div>
  );
};

export default RenderSchedule;
