import { useDispatch, useSelector } from "react-redux";
import { selectCarpoolsPartOf, selectUserDisplayName, selectUserId } from "../store/user/userSlice";
import { useState } from "react";
import { requestAccessAction } from "../store/carpools/carpoolsActions";
import { allowedToRequestAccess } from "../dbQueries";
import { setError } from "../store/global/globalSlice";

function RequestAccess(props: {carpoolId: string}) {
  const { carpoolId } = props;
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId) || "";
  const userDisplayName = useSelector(selectUserDisplayName) || "";
  const [accessRequested, setAccessRequested] = useState<boolean>(false);
  const carpoolsPartOf = useSelector(selectCarpoolsPartOf);

  const handleAccessRequest = async () => {
    const allowedToRequest = await allowedToRequestAccess(carpoolId, userId, carpoolsPartOf);
    if (allowedToRequest.error) {
      console.error(allowedToRequest.error);
      dispatch(setError(allowedToRequest.error));
    } else if (allowedToRequest.issue) {
      alert(allowedToRequest.issue);
    } else {
      dispatch(requestAccessAction({carpoolId, person: {userId, userDisplayName}}));
      setAccessRequested(true);
    }
  }

  return (
    <div>
      <p>You are not a member of this carpool!</p>
      {!accessRequested ? <button className='primary-btn' onClick={handleAccessRequest}>Request Access to Become a Member</button> : <p>You requested access!</p>}
    </div>
  );
}
export default RequestAccess;