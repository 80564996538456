import { createAction } from "@reduxjs/toolkit";
import { CarpoolDbState, FamilyDbState, HistoryItem, Member, RequestingAccessPerson, ScheduleEvent } from "../storeStates";

export interface fetchInitialCarpoolDataActionFormat {
  type: string;
  payload: fetchInitialCarpoolDataActionPayload;
}

export interface fetchInitialCarpoolDataActionPayload {
  userId: string;
}

export const fetchInitialCarpoolDataAction = createAction<fetchInitialCarpoolDataActionPayload>('carpools/fetchInitialCarpoolDataAction');

export interface addFamilyActionPayload {
  carpoolId: string;
  userId: string;
  userDisplayName: string;
  newFamily: FamilyDbState;
  shouldJoinFamily: boolean;
  color: string;
}

export interface addFamilyActionFormat {
  type: string;
  payload: addFamilyActionPayload;
}

export const addFamilyAction = createAction<addFamilyActionPayload>('carpools/addFamilyActionFormat');

export interface removeRequestActionPayload {
  carpoolId: string;
  person: RequestingAccessPerson;
}

export interface removeRequestActionFormat {
  type: string;
  payload: removeRequestActionPayload;
}

export const removeRequestAction = createAction<removeRequestActionPayload>('carpools/removeRequestAction');

export interface grantAccessActionPayload extends removeRequestActionPayload {
  userId: string;
  userDisplayName: string;
  carpoolName: string;
}

export interface grantAccessActionFormat {
  type: string;
  payload: grantAccessActionPayload;
}

export const grantAccessAction = createAction<grantAccessActionPayload>('carpools/grantAccessAction');

export interface requestAccessActionPayload {
  carpoolId: string;
  person: RequestingAccessPerson;
}

export interface requestAccessActionFormat {
  type: string;
  payload: requestAccessActionPayload;
}

export const requestAccessAction = createAction<requestAccessActionPayload>('carpools/requestAccessAction');

export interface joinFamilyActionFormat {
  type: string;
  payload: joinFamilyActionPayload;
}

export interface joinFamilyActionPayload {
  userId: string;
  carpoolId: string;
  familyId: string;
  familyName: string;
  newMember: Member;
  historyItem: HistoryItem;
}

export const joinFamilyAction = createAction<joinFamilyActionPayload>('carpools/joinFamilyAction');

export interface addNewCarpoolActionFormat {
  type: string;
  payload: addNewCarpoolActionPayload;
}

export interface addNewCarpoolActionPayload {
  userId: string;
  userDisplayName: string;
  newCarpool: CarpoolDbState;
  addedToDbCarpoolId: string;
}

export const addNewCarpoolAction = createAction<addNewCarpoolActionPayload>('carpools/addNewCarpoolAction');

export interface createRotationActionFormat {
  type: string;
  payload: string;
}

export const createRotationAction = createAction<string>('carpools/createRotationAction');

//----
export interface addScheduleEventActionPayload {
  carpoolId: string;
  newEvent: ScheduleEvent;
}

export interface addScheduleEventActionFormat {
  type: string;
  payload: addScheduleEventActionPayload;
}
export const addScheduleEventAction = createAction<addScheduleEventActionPayload>('carpools/addScheduleEventAction');

//----
export interface editScheduleEventActionPayload {
  carpoolId: string;
  newEvent: ScheduleEvent;
}

export interface editScheduleEventActionFormat {
  type: string;
  payload: editScheduleEventActionPayload;
}
export const editScheduleEventAction = createAction<editScheduleEventActionPayload>('carpools/editScheduleEventAction');

//----
export interface deleteScheduleEventActionPayload {
  carpoolId: string;
  id: string;
}

export interface deleteScheduleEventActionFormat {
  type: string;
  payload: deleteScheduleEventActionPayload;
}
export const deleteScheduleEventAction = createAction<deleteScheduleEventActionPayload>('carpools/deleteScheduleEventAction');

//----
export interface updateTurnsCountActionPayload {
  carpoolId: string;
  newEvt: ScheduleEvent | null;
  origEvt: ScheduleEvent | null;
}

export interface updateTurnsCountActionFormat {
  type: string;
  payload: updateTurnsCountActionPayload;
}

export const updateTurnsCountAction = createAction<updateTurnsCountActionPayload>('carpools/updateTurnsCountAction');
//----

export interface manualUpdatesActionFormat{
  type: string;
  payload: manualUpdatesActionPayload;
}

export interface manualUpdatesActionPayload {
  carpoolId: string;
}
export const manualUpdatesAction = createAction<manualUpdatesActionPayload>('carpools/manualUpdatesAction');

//----
